import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getSessionToken, login } from '../account/AccountSlice';
import { Group, getGroups as getGroupsAPI } from './GroupsAPI';

export interface GroupsState {
  status: 'idle' | 'loading' | 'failed';
  error: Error | null;
  loaded: Group[] | null;
}

const initialState: GroupsState = {
  status: 'idle',
  error: null,
  loaded: null
};

export const loadGroups = createAsyncThunk(
  'groups/load',
  async (_, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Utente non autenticato");
      const response = await getGroupsAPI(sessionToken);
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(loadGroups.fulfilled, (state, action: PayloadAction<Group[]>) => {
        state.status = 'idle';
        state.loaded = action.payload;
      })
      .addMatcher(
        isAnyOf(loadGroups.pending),
        (state) => {
          state.status = 'loading';
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(loadGroups.rejected),
        (state, action) => {
          state.status = 'failed';
          const error = action.payload;
          state.error = (error instanceof Error) ? error : new Error(error as string);
        }
      )
      .addMatcher(
        isAnyOf(login.fulfilled),
        (state) => {
          state.loaded = null;
        }
      );
  },
});

export const isLoading = (state: RootState) => state.groups.status === 'loading';
export const getError = (state: RootState) => state.groups.error;
export const getLoadedGroup =
  (id: number) =>
    (state: RootState) =>
      state.groups.loaded?.find(group => group.id === id);
export const getLoadedGroups = (state: RootState) => state.groups.loaded;

export default groupsSlice.reducer;