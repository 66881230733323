import { Capacitor } from '@capacitor/core';
import { FirebaseApp } from 'firebase/app';
import {ActionPerformed, PushNotifications, Token} from '@capacitor/push-notifications';
import { createAsyncThunk, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getLoggedAccount, getSessionToken } from '../account/AccountSlice';
import { getURLRedirectFromData, NotificationData, registerToken as registerTokenAPI } from './NotificationsAPI';
import { getFirebaseConfig, loadSettings } from '../settings/SettingsSlice';
import { FCM } from '@capacitor-community/fcm';
import {Channel} from "@capacitor/push-notifications/dist/esm/definitions";
import { isIOS } from "../../app/utils";

export interface NotificationsState {
  status: 'idle' | 'loading' | 'failed';
  error: Error | null;
  currentToken: string | null;
  lastReceived: FirebaseMessage | null;
}

const initialState: NotificationsState = {
  status: 'idle',
  error: null,
  currentToken: null,
  lastReceived: null
};

type FirebaseMessage = {
  text: string;
  link?: string;
}

let app: FirebaseApp;
export const checkPermissions = createAsyncThunk(
  'notifications/check_permissions',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const state = await getState() as RootState;
    const previous = getCurrentToken(state);
    return new Promise<string>(async (resolve, reject) => {
      if(["android","ios"].includes(Capacitor.getPlatform()))
        nativeGetToken(resolve, reject);
      else if(window.electron){
        electronGetToken(resolve, reject);
      }else{
        const { isSupported } = await import("firebase/messaging");
        if(await isSupported()){
          try{
            if(!app){
              const settings = await dispatch(loadSettings()).then(unwrapResult);
              const { initializeApp } = await import('firebase/app');
              app = initializeApp(settings.firebaseConfig.web);
            }
            const config = getFirebaseConfig(state);
            webGetToken(
              resolve,
              reject,
              (message: FirebaseMessage) =>
                dispatch(receivedNotification(message)),
              config.web.vapidKey
            );
          }catch(e){
            reject(new Error(`${e}`));
          }
        } else {
          reject(new Error("Sistema non supportato."))
        }
      }
    }).then(
      async token => {
        if(token !== previous){
          const sessionToken = getSessionToken(state);
          const me = getLoggedAccount(state);
          if(!sessionToken || !me)
            throw new Error('Utente non autenticato');
          await registerTokenAPI(token, me.email, sessionToken);
        }
        return token;
      }
    ).catch(error => rejectWithValue(error));
  }
);

function processNotificationData(data: NotificationData){
  const url = getURLRedirectFromData(data);
  if(url) window.location.href = url;
}

function electronGetToken(
  resolve: (token:string) => any, reject: (error: Error) => any
){
  window.electron.onNotificationServiceStarted(
    (_: any, token: any) => resolve(token as string)
  );
  window.electron.onNotificationServiceError(
    (_: any, error: any) => reject(error as Error)
  );
  window.electron.startNotificationService();
}

async function nativeGetToken(
  resolve: (token:string) => any, reject: (error: Error) => any
){
  try{
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      reject(new Error('Permesso notifiche non concesso'));
    }else{

      await PushNotifications.register();
      await PushNotifications.addListener('registration',
        (token: Token) => {
          FCM.getToken()
            .then((result: any) => {
              resolve(result.token);
            })
            .catch((error: any) => {
              console.error(error);
              reject(new Error('Un errore è avvenuto registrando le notifiche da mobile'));
            });
        }
      );
      await PushNotifications.addListener('registrationError',
        (error: any) => {
          console.error(error);
          reject(new Error('Un errore è avvenuto registrando le notifiche da mobile'));
        }
      );

      if (!isIOS()) {
        const result = await PushNotifications.listChannels();
        if(result.channels.every(channel => channel.id !== 'fcm_fallback_notification_channel')){
          const notificationChannel = {
            id: 'fcm_fallback_notification_channel',
            name: 'Notifiche Meraki',
            description: 'Notifiche ricevute da Meraki App',
            sound: 'notify_sound.wav',
            importance: 5,
            visibility: 1,
            lights: true,
            vibration: true
          } as Channel;
          await PushNotifications.createChannel(notificationChannel);
        }
      }

      await PushNotifications.addListener('pushNotificationActionPerformed',
        (action: ActionPerformed) => {
          processNotificationData(action.notification.data);
        }
      );
    }
  }catch(e){
    reject(new Error(`${e}`));
  }
}

async function webGetToken(
  resolve: (token:string) => any, reject: (error: Error) => any,
  showSnackbarNotification: (message: FirebaseMessage) => void, vapidKey?: string
){
  try{
    const { getMessaging, getToken, onMessage  } = await import("firebase/messaging");
    const messaging = getMessaging();
    const permission = await Notification.requestPermission();
    if(permission === "granted"){
      console.log('granted');
      getToken(messaging, { vapidKey })
        .then((currentToken) => {
          if(currentToken){

            onMessage(messaging, (payload) => {
              console.log('Message received. ', payload);
              // const data = {
              //   target: payload.data?.target || '',
              //   location: payload.data?.location || '',
              //   room: payload.data?.room || ''
              // }
              // const link = getURLRedirectFromData(data) || undefined;
              const link = '';

              const title = payload.notification?.title || 'Notifica';
              const body = payload.notification?.body || 'Notifica ricevuta';
              const message = `${title}: ${body}`;
              showSnackbarNotification({text: message, link});
            });

            resolve(currentToken);
          }
        }).catch((error) => {
          console.error(error);
          reject(new Error('Un errore è avvenuto registrando le notifiche'));
        });
    }else
      reject(
        new Error('Permesso non concesso. Non saranno inviate notifiche a questo dispositivo.')
      );
  }catch(e){
    reject(new Error(`${e}`));
  }
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    receivedNotification: (state, action: PayloadAction<FirebaseMessage>) => {
      state.lastReceived = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkPermissions.pending, (state) => {
        state.status = 'loading';
        state.error = new Error(
          "Servizio notifiche non inizializzato." +
            (Capacitor.getPlatform() === "ios" ?
              " Connessione mancata ai servizi Apple." :
              "")
        );
      })
      .addCase(
        checkPermissions.fulfilled,
        (state, action: PayloadAction<string | null>) => {
          state.status = 'idle';
          state.currentToken = action.payload;
          state.error = null;
        }
      ).addCase(checkPermissions.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error =
          action.payload instanceof Error ?
          action.payload :
          new Error(action.payload as string);
      });
  },
});

export const isLoading = (state: RootState) => state.notifications.status === 'loading';
export const getCurrentToken = (state: RootState) => state.notifications.currentToken;
export const isActive = (state: RootState) => state.notifications.currentToken !== null;
export const getError = (state: RootState) => state.notifications.error;
export const getLastReceived = (state: RootState) => state.notifications.lastReceived;

export const { receivedNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
