import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ReactElement } from "react";
import { RootState } from "../../app/store";

export type NavBarAction = {
  routine: () => any;
}
export type IconedNavBarAction = NavBarAction & {
  icon: ReactElement;
}
export type NavBarActionMenu = {
  actions: Record<string, NavBarAction>
}

type ConfirmQuestion = {
  title: string;
  description: string | React.ReactNode;
  confirmAction: () => any;
}

type LockingGroup = {
  queue: string[];
  current: string[];
};

const LOCKING_GROUP_SIZE = 3;

export interface UIState {
  navBarAction: IconedNavBarAction | null;
  navBarMenu: NavBarActionMenu | null;
  fileInViewer: string | null;
  askedForAddToHome: boolean;
  askedForCookies: boolean;
  scrollUpOnChange: boolean;
  confirmQuestion: ConfirmQuestion | null;
  showedDiagnosticsDialog: boolean;
  lockingGroups: Record<string, LockingGroup>;
}

const initialState: UIState = {
  navBarAction: null,
  navBarMenu: null,
  fileInViewer: null,
  askedForAddToHome: false,
  askedForCookies: false,
  scrollUpOnChange: true,
  confirmQuestion: null,
  showedDiagnosticsDialog: false,
  lockingGroups: {}
};

function checkGroup(group: LockingGroup){
  if(
    group.current.length < LOCKING_GROUP_SIZE &&
    group.queue?.length > 0
  ){
    const nextId = group.queue.shift();
    group.current.push(nextId as string);
  }
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    addToLockingGroup: (state, action: PayloadAction<{group: string; id: string}>) => {
      const { group, id } = action.payload;
      if(!state.lockingGroups[group])
        state.lockingGroups[group] = {
          queue: [],
          current: []
        };
      state.lockingGroups[group].queue.push(id);
      checkGroup(state.lockingGroups[group]);
      if(
        state.lockingGroups[group].queue.length === 0 &&
        state.lockingGroups[group].current.length === 0
      )
        delete state.lockingGroups[group];
    },
    removeFromLockingGroup: (state, action: PayloadAction<{group: string; id: string}>) => {
      const { group, id } = action.payload;
      if(state.lockingGroups[group]){
        state.lockingGroups[group].queue = state.lockingGroups[group].queue?.filter(
          element => element !== id
        );
        state.lockingGroups[group].current = state.lockingGroups[group].current?.filter(
          element => element !== id
        );
        checkGroup(state.lockingGroups[group]);
        if(
          state.lockingGroups[group].queue.length === 0 &&
          state.lockingGroups[group].current.length === 0
        )
          delete state.lockingGroups[group];
      }
    },
    setNavBarAction: (state, action: PayloadAction<IconedNavBarAction>) => {
      state.navBarAction = action.payload;
    },
    setNavBarActionMenu: (state, action: PayloadAction<NavBarActionMenu>) => {
      state.navBarMenu = action.payload;
    },
    unsetNavBarAction: (state) => {
      state.navBarAction = null;
    },
    unsetNavBarActionMenu: (state) => {
      state.navBarMenu = null;
    },
    viewFile: (state, action: PayloadAction<string>) => {
      state.fileInViewer = action.payload;
    },
    exitFileViewer: state => {
      state.fileInViewer = null;
    },
    showedAddToHome: (state) => {
      state.askedForAddToHome = true;
    },
    showedCookiesBanner: (state) => {
      state.askedForCookies = true;
    },
    disableScrollUp: (state) => {
      state.scrollUpOnChange = false;
    },
    enableScrollUp: (state) => {
      state.scrollUpOnChange = true;
    },
    closeConfirmQuestion: (state) => {
      state.confirmQuestion = null;
    },
    openConfirmQuestion: (state, action: PayloadAction<ConfirmQuestion>) => {
      state.confirmQuestion = action.payload;
    },
    showDiagnosticsDialog: (state) => {
      state.showedDiagnosticsDialog = true;
    },
    hideDiagnosticsDialog: (state) => {
      state.showedDiagnosticsDialog = false;
    },
  },
  extraReducers: (builder) => { }
});

export const getFileInViewer = (state: RootState) => state.ui.fileInViewer;
export const getNavBarAction = (state: RootState) => state.ui.navBarAction;
export const getNavBarMenu = (state: RootState) => state.ui.navBarMenu;
export const hasAskedForAddToHome = (state: RootState) => state.ui.askedForAddToHome;
export const hasAskedForCookies = (state: RootState) => state.ui.askedForCookies;
export const needScrollUpOnChange = (state: RootState) => state.ui.scrollUpOnChange;
export const getConfirmQuestion = (state: RootState) => state.ui.confirmQuestion;
export const isShowedDiagnosticsDialog = (state: RootState) => state.ui.showedDiagnosticsDialog;
export const isCurrentInLockingGroup =
  (group: string, id: string) =>
    (state: RootState) => !!state.ui.lockingGroups[group]?.current.includes(id);

export const {
  setNavBarAction, unsetNavBarAction, viewFile, exitFileViewer, showedAddToHome,
  disableScrollUp, enableScrollUp, setNavBarActionMenu, unsetNavBarActionMenu,
  closeConfirmQuestion, openConfirmQuestion, showedCookiesBanner, showDiagnosticsDialog,
  hideDiagnosticsDialog, addToLockingGroup, removeFromLockingGroup
} = uiSlice.actions;

const persistConfig = {
  key: 'ui',
  storage,
  whitelist: ['askedForAddToHome', 'askedForCookies'],
};

export default persistReducer(persistConfig, uiSlice.reducer);