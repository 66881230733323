import { AnyAction, createAsyncThunk, createSlice, Dispatch, isAnyOf, Middleware, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';
import { RootState } from '../../app/store';
import { FixedQueue } from '../../app/utils';
import { UserDetails } from '../account/AccountAPI';
import { getLoggedAccount, getSessionToken, getUser, login, logout } from '../account/AccountSlice';
import { Group } from '../groups/GroupsAPI';
import { loadGroups } from '../groups/GroupsSlice';
import { Project, ProjectEntity } from '../projects/ProjectsAPI';
import { loadMine } from '../projects/ProjectsSlice';
import { createTransform, persistReducer } from 'redux-persist';
import {
  fetchChatMessages as fetchChatMessagesAPI,
  sendChatMessage as sendChatMessageAPI,
  openChatConnection as openChatConnectionAPI,
  ChatConnection,
  ReceivedChatMessage,
  RoomId,
  ChatMessageRequest,
  ServerRoomId,
  Room,
  ServerRoom,
  reconnectToChatServer as reconnectToChatServerAPI,
  RoomManager,
  MessagesStorage,
  addMessage,
  StoredReceivedChatMessage,
  EMPTY_LIST,
} from './CommunicationAPI';
import { CapacitorStorage } from '../../app/persist';
import { Capacitor } from '@capacitor/core';
import storage from 'redux-persist/lib/storage';

const chatConnection: ChatConnection = new ChatConnection();

type RequestId = number;

export interface CommunicationState {
  status: 'idle' | 'loading' | 'failed';
  errors: FixedQueue<ErrorRequestResult>;
  last5Sent: FixedQueue<RequestId>;
  lastReceivedTimestamp: Record<RoomId, number>;
  lastTimestamps: Record<RoomId, number>;
  unreadChats: RoomId[];
  roomIdsPerProject: Record<string, RoomId[]>;
  lastSeen: Record<RoomId, number>;
  pickedFileForChat: ProjectEntityRef | null;
  myRooms: Room[] | null;
  roomsMembers: Record<RoomId, ChatAlias[]>;
  chatConnected: boolean;
  connectionSetted: boolean;
  store: MessagesStorage;
  lastDraft?: {
    room: RoomId,
    message: string
  }
}

const initialState: CommunicationState = {
  status: 'idle',
  errors: new FixedQueue(5),
  last5Sent: new FixedQueue(5),
  lastReceivedTimestamp: {},
  lastTimestamps: {},
  unreadChats: [],
  roomIdsPerProject: {},
  lastSeen: {},
  pickedFileForChat: null,
  myRooms: null,
  roomsMembers: {},
  chatConnected: false,
  connectionSetted: false,
  store: {},
};

type SendRequest ={
  id: RequestId;
}

type BaseRequestResult = {
  id: RequestId;
}
type SuccessfulRequestResult = BaseRequestResult & {
  result: true;
}
type ErrorRequestResult = BaseRequestResult & {
  result: false;
  error: Error;
}
type RequestResult = SuccessfulRequestResult | ErrorRequestResult;

export type ChatOpeningResult = RequestResult & {
  socket: Socket;
}

export const openChatConnection = createAsyncThunk<ChatOpeningResult, SendRequest>(
  'communication/open_chat_connection',
  async ({ id }, { rejectWithValue, getState, dispatch }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const socket = await openChatConnectionAPI(
        sessionToken,
        () => dispatch(chatConnected()),
        () => dispatch(chatDisconnected()),
      );
      return { id, result: true, socket };
    }catch(error){
      return rejectWithValue({
        id, result: false, error
      });
    }
  }
);

export const initRoom = createAsyncThunk<void, ServerRoom>(
  'communication/init_room',
  async (room, { dispatch, getState }) => {
    const state = await getState() as RootState;
    const connection = getChatConnection(state);
    if(!connection) throw new Error("Connessione al server chat assente.");
    const roomId = RoomManager.generateRoomId(room.id);
    dispatch(addRoom({ room }))
    if (
      room.lastUpdateTimestamp &&
      state.communication.lastSeen[roomId] < (room.lastUpdateTimestamp*1000)
    )
      await fetchChatMessagesAPI(
        Date.now(),
        30,
        roomId,
        connection
      );
  }
);

export const getUsersByChatId = createAsyncThunk(
  'communication/get_users_by_chat_id',
  async (chatid: string, { rejectWithValue, dispatch }) => {
    try{
      let group = RoomManager.extractFromRoomId(chatid) as ServerRoomId | undefined;
      if(!group) throw new Error("Chat non disponibile");
      if(!(group instanceof Array)) group = [group];
      let members: ChatAlias[] = [];
      for(const userIdentity of group){
        if(userIdentity.indexOf('@') > -1)
          members.push(
            new ChatUser(await dispatch(
              getUser(userIdentity)
            ).then(unwrapResult))
          )
        else if(userIdentity.startsWith('group:')){
          const groups = await dispatch(loadGroups()).then(unwrapResult)
          const groupRef = userIdentity.substring(6);
          const group =
            groups.find(group => `${group.id}` === groupRef) ||
            groups.find(group => group.name === groupRef);
          if(group)
            members.push(new ChatGroup(group));
        }
        else if(userIdentity.startsWith('project:')){
          const projectId = userIdentity.substring(8);
          const project = (await dispatch(loadMine()).then(unwrapResult))
            .find(project => project.id === projectId);
          if(project)
            members.push(new ChatProject(project));
        }
      }
      return { chatid, members }
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

type SendChatRequest = SendRequest & {
  room: RoomId;
  message: ChatMessageRequest;
}
type SendChatResult = RequestResult & {
  room?: ServerRoomId;
  message: ReceivedChatMessage | null
}

export const sendChatMessage = createAsyncThunk<SendChatResult, SendChatRequest>(
  'communication/send_chat_message',
  async (
    {id, room, message}, { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const connection = getChatConnection(state);
      if(!connection) throw new Error("Connessione al server chat assente.");
      const me = getLoggedAccount(state);
      if(!me) throw new Error("Utente non autenticato.");
      const convertedMessage = await sendChatMessageAPI(
        room,
        message,
        connection
      );
      return { id, result: true, room, message: convertedMessage };
    }catch(error){
      return rejectWithValue({
        id, result: false, error, message: null
      });
    }
  }
);

type FetchChatMessagesRequest = {
  id: number;
  fromDate: number;
  limit: number;
  room: RoomId;
}

export const fetchChatMessages = createAsyncThunk<
  SuccessfulRequestResult, FetchChatMessagesRequest
>(
  'communication/fetch_chat_messages',
  async (
    {id, fromDate, limit, room}, { rejectWithValue, getState }
  ) => {
    try{
      const state = await getState() as RootState;
      const connection = getChatConnection(state);
      if(!connection) throw new Error("Connessione al server chat assente.");
      const me = getLoggedAccount(state);
      if(!me) throw new Error("Utente non autenticato.");
      await fetchChatMessagesAPI(
        fromDate,
        limit,
        room,
        connection
      );
      return { id, result: true };
    }catch(error){
      return rejectWithValue({
        id, result: false, error
      });
    }
  }
);
export const chatReconnect = createAsyncThunk(
  'communication/chat_reconnect',
  async (
    _, { rejectWithValue, getState }
  ) => {
    try{
      const state = await getState() as RootState;
      const connection = getChatConnection(state);
      if (!connection.isConnected())
        await reconnectToChatServerAPI(connection);
    }catch(error){
      return rejectWithValue({
        id: 0, result: false, error
      });
    }
  }
);

export type ProjectEntityRef = {
  entity: ProjectEntity;
  location: string;
}

export const communicationSlice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    setDraft: (state, action: PayloadAction<{ room: RoomId, message: string }>) => {
      if (!action.payload.message)
        state.lastDraft = undefined
      else
        state.lastDraft = action.payload
    },
    removeError: (state, action: PayloadAction<RequestId>) => {
      state.errors.remove(error => error.id !== action.payload);
    },
    removeSent: (state, action: PayloadAction<RequestId>) => {
      state.last5Sent.remove(sent => sent !== action.payload);
    },
    receivedMessage: (state, action: PayloadAction<ReceivedChatMessage>) => {
      const message: StoredReceivedChatMessage = {...action.payload, checked: true}
      state.store = addMessage(state.store, message)
      const roomId = RoomManager.generateRoomId(message.room);
      state.lastReceivedTimestamp[roomId] = message.timestamp;
      if(
        !state.lastTimestamps[roomId] ||
        message.timestamp > state.lastTimestamps[roomId]
      ){
        state.lastTimestamps[roomId] = message.timestamp;
      }
      if(
        state.lastSeen[roomId] &&
        state.lastTimestamps[roomId] > state.lastSeen[roomId] &&
        !state.unreadChats.includes(roomId)
      )
        state.unreadChats.push(roomId);
    },
    saw: (
      state, action: PayloadAction<{ room: RoomId, timestamp: number }>
    ) => {
      const { room, timestamp } = action.payload;
      if (state.lastSeen[room] > 17489040804000) {
        state.lastSeen[room] = Math.floor(state.lastSeen[room]/1000)
      }
      if(!state.lastSeen[room] || timestamp > state.lastSeen[room])
        state.lastSeen[room] = timestamp;
      if(
        state.lastSeen[room] >= state.lastTimestamps[room] &&
        state.unreadChats.includes(room)
      )
        state.unreadChats = state.unreadChats.filter(chats => chats !== room);
    },
    addRoom: (state, action: PayloadAction<{ room: ServerRoom }>) => {
      const serverRoom = action.payload.room;
      const roomId = RoomManager.generateRoomId(serverRoom.id);
      const participants = serverRoom.id instanceof Array ? serverRoom.id : [serverRoom.id];
      if(participants.find(participant => participant.startsWith('project:'))){
        const projectIds = participants.filter(
          participant => participant.startsWith('project:')
        ).map(
          participant => participant.substring(8)
        );
        for(const projectId of projectIds){
          if(!state.roomIdsPerProject[projectId])
            state.roomIdsPerProject[projectId] = [];
          state.roomIdsPerProject[projectId].push(roomId);
        }
      }
      if(state.myRooms === null) state.myRooms = [];
      const room: Room = {
        id: roomId,
        lastUpdateTimestamp:
          serverRoom.lastUpdateTimestamp ?
          serverRoom.lastUpdateTimestamp * 1000 :
          undefined
      }
      if(!state.myRooms.find(savedRoom => savedRoom.id === roomId)){
        state.myRooms = [...state.myRooms, room];
      }
      if(
        room.lastUpdateTimestamp &&
        (
          !state.lastSeen[roomId] ||
          room.lastUpdateTimestamp > state.lastSeen[roomId]
        ) &&
        !state.unreadChats.includes(roomId)
      )
        state.unreadChats.push(roomId);
    },
    removeRoom: (state, action: PayloadAction<ServerRoomId>) => {
      const serverRoom = action.payload;
      const room = RoomManager.generateRoomId(serverRoom);
      state.myRooms = state.myRooms?.filter(savedRoom => savedRoom.id === room) || null;
    },
    pickFileForChat: (state, action: PayloadAction<ProjectEntityRef>) => {
      state.pickedFileForChat = action.payload;
    },
    clearPickedForChat: (state) => {
      state.pickedFileForChat = null;
    },
    chatConnected: (state) => {
      state.chatConnected = true;
    },
    chatDisconnected: (state) => {
      state.chatConnected = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        logout.fulfilled,
        (state) => {
          state.store = {}
          chatConnection.disconnect();
          chatConnection.flush();
        }
      )
      .addCase(
        openChatConnection.fulfilled,
        (state, action: PayloadAction<ChatOpeningResult>) => {
          state.status = 'idle';
          chatConnection.setConnection(action.payload.socket);
          state.connectionSetted = true
        }
      )
      .addCase(
        getUsersByChatId.fulfilled,
        (state, action: PayloadAction<{ chatid: RoomId, members: ChatAlias[] }>) => {
          state.status = 'idle';
          const { chatid, members } = action.payload;
          state.roomsMembers[chatid] = members;
        }
      )
      .addCase(
        openChatConnection.pending, (state) => {
          state.chatConnected = false;
        }
      )
      .addMatcher(
        isAnyOf(sendChatMessage.fulfilled),
        (state, action: PayloadAction<SendChatResult>) => {
          state.status = 'idle';
          const { id, room } = action.payload;
          const message: StoredReceivedChatMessage | null =
            action.payload.message ? {
              ...action.payload.message,
              checked: true
            } : null
          state.last5Sent = state.last5Sent.push(id);
          if(room && message){
            message.checked = true
            state.store = addMessage(state.store, message);
            const roomId = RoomManager.generateRoomId(room);
            state.lastReceivedTimestamp[roomId] = Date.now();
          }
        }
      )
      .addMatcher(
        isAnyOf(
          sendChatMessage.pending, openChatConnection.pending,
          fetchChatMessages.pending, getUsersByChatId.pending,
          chatReconnect.pending
        ),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        isAnyOf(
          sendChatMessage.rejected, openChatConnection.rejected,
          fetchChatMessages.rejected, chatReconnect.rejected
        ),
        (state, action) => {
          state.status = 'failed';
          const result = action.payload as ErrorRequestResult;
          state.errors = state.errors.push(result)
        }
      )
      .addMatcher(
        isAnyOf(login.fulfilled),
        (state) => {
          state.last5Sent = new FixedQueue(5);
          state.lastReceivedTimestamp = {};
          state.lastTimestamps = {};
          state.roomIdsPerProject = {};
          state.unreadChats = [];
          state.pickedFileForChat = null;
          state.myRooms = null;
          state.roomsMembers = {};
          state.store = {}
        }
      )
      .addMatcher(
        isAnyOf(
          sendChatMessage.fulfilled, openChatConnection.fulfilled,
          fetchChatMessages.fulfilled, getUsersByChatId.fulfilled,
          chatReconnect.fulfilled
        ), (state) => {
          state.status = 'idle';
        }
      );
  },
});

export const {
  removeError, removeSent, receivedMessage, addRoom, removeRoom, setDraft
} = communicationSlice.actions;

export const isLoading = (state: RootState) => state.communication.status === 'loading';
export const getError =
(requestId: RequestId) =>
  (state: RootState) => state.communication.errors.find(error => error.id === requestId);
export const getLastError =
  (state: RootState) => state.communication.errors.getLast();
export const isSent =
  (requestId: RequestId) =>
    (state: RootState) => state.communication.last5Sent.includes(requestId);
export const getChatConnection = (state: RootState) => chatConnection;
export const isChatConnected =
  (state: RootState) =>
    state.communication.chatConnected &&
    state.communication.connectionSetted;
export const isChatActive = (state: RootState) => chatConnection.isActive();
export const isChatDisconnected = (state: RootState) => !state.communication.chatConnected;
export const getMessages =
  (recipient: RoomId) =>
    (state: RootState) => state.communication.store[recipient] || EMPTY_LIST;
export const getLastReceivedTimestamp =
  (recipient: string) =>
    (state: RootState) =>
      state.communication.lastReceivedTimestamp[
        RoomManager.generateRoomId(recipient)
      ];
export const getPickedFileForChat =
  (state: RootState) => state.communication.pickedFileForChat;
export const getMyRooms = (state: RootState) => state.communication.myRooms;
export const isUserInRoom =
  (roomId: RoomId) =>
    (state: RootState) =>
      Boolean(state.communication.myRooms?.find(room => room.id === roomId))
export const getRoomLastUpdateTime =
  (roomId: RoomId) =>
    (state: RootState) =>
      state.communication.myRooms?.find(
        room => room.id === roomId
      )?.lastUpdateTimestamp;
export const getUnreadChatsProject = (projectId: string) => (state: RootState) => {
  const roomIds = state.communication.roomIdsPerProject[projectId] || [];
  return roomIds.filter(
    roomId => state.communication.unreadChats.includes(roomId)
  );
}
export const getUnreadChatCountOfProject = (projectId: string) => (state: RootState) => {
  const unreadRooms = getUnreadChatsProject(projectId)(state);
  return unreadRooms.length;
};
export const isChatUnread = (room: RoomId) => (state: RootState) => {
  return state.communication.unreadChats.includes(room);
};
export const getDraft = (room: RoomId) => (state: RootState) => {
  return state.communication.lastDraft?.room === room
    ? state.communication.lastDraft.message
    : null
};

class ChatAlias{
  details: any;
  constructor(details: any){
    this.details = details;
  }
  getName(): string{
    return '';
  }
}
export class ChatUser extends ChatAlias{
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(details: UserDetails){
    super(details)
  }
  getName() {
    return (this.details as UserDetails).name;
  }
}
export class ChatGroup extends ChatAlias{
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(details: Group){
    super(details)
  }
  getName() {
    return (this.details as Group).name;
  }
}
export class ChatProject extends ChatAlias{
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(details: Project){
    super(details)
  }
  getName() {
    return 'Tutto il team';
  }
}
export const getLoadedUsersByChatId =
  (chatid: string) => (state: RootState) => state.communication.roomsMembers[chatid];

export const chatMiddleware: Middleware<any, any, Dispatch<AnyAction>> =
  store => next => action => {
    if(action.type === "communication/open_chat_connection/fulfilled"){
      const { socket } = action.payload as ChatOpeningResult;
      socket.on('message', (message) => {
        console.debug("Message received", message)
        store.dispatch(receivedMessage(message));
      })
      socket.on('add-room', (room: ServerRoom) => {
        console.debug("Room joined", room)
        store.dispatch(initRoom(room) as unknown as AnyAction);
      })
      socket.on('exit-room', (roomId) => {
        console.debug("Room exited", roomId)
        store.dispatch(removeRoom(roomId));
      })
      socket.on('disconnect', (error) => {
        console.debug("Chat disconnected", error)
        store.dispatch(chatDisconnected())
      })
      socket.emit('get-rooms');
    }
    return next(action)
  }

export const {
  saw, pickFileForChat, clearPickedForChat, chatConnected, chatDisconnected
} = communicationSlice.actions;

const MessagesStoreTransform = createTransform(
  (inboundState: MessagesStorage) => {
    const messagesCache: any = {};
    for (const key in inboundState) {
      const value = inboundState[key];
      messagesCache[key] = value.slice(-100);
    }
    return messagesCache;
  },
  (outboundState: MessagesStorage) => outboundState,
  { whitelist: ['store'] }
);

const persistConfig = {
  key: 'communication',
  storage: CapacitorStorage,
  transforms: [MessagesStoreTransform],
  whitelist: ['lastSeen', 'store', 'lastDraft'],
};

const webPersistConfig = {
  key: 'communication',
  storage: storage,
  whitelist: ['lastSeen', 'lastDraft'],
};

export default (
  Capacitor.isNativePlatform()
    ? persistReducer<CommunicationState>(persistConfig, communicationSlice.reducer)
    : persistReducer<CommunicationState>(webPersistConfig, communicationSlice.reducer)
  );
