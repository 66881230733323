import React, { ErrorInfo, ReactNode } from "react";
import { reportCrash } from "../../features/crash/CrashAPI";

type AppErrorBoundaryProps = {
  children?: ReactNode;
};

type AppErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
}

const buttonStyle: React.CSSProperties = {
  background: 'rgb(19, 120, 171)',
  color: 'rgb(255, 255, 255)',
  boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
  minWidth: 64,
  padding: '6px 16px',
  outline: 0,
  border: 0,
  cursor: 'pointer',
  userSelect: 'none',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1.75',
  letterSpacing: '0.02857em',
  textTransform: 'uppercase',
  borderRadius: '8px',
  wordBreak: 'break-word'
};

export default class AppErrorBoundary
  extends React.Component<AppErrorBoundaryProps, AppErrorBoundaryState>
{
  constructor(props: AppErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error): AppErrorBoundaryState {
    reportCrash(error.stack || error.message)
      .catch(()=>{});
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.setState({
      hasError: true,
      error
    })
  }

  reloadPage(){
    window.location.href = window.location.origin;
  }

  restoreApp(){
    for(let i = localStorage.length - 1; i >= 0; i--){
      const key = localStorage.key(i);
      if(key && key.startsWith('persist:')){
        localStorage.removeItem(key);
      }
    }
    this.reloadPage();
  }

  getErrorString(): string{
    if(this.state.error){
      if(this.state.error.stack)
        return this.state.error.stack;
      return this.state.error.message;
    }
    return "";
  }

  render() {
    if(this.state.hasError){
      return <div
        style={{
          height: '100%',
          minHeight: '100vh',
          background: '#f2f2f2',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '16px',
          overflowY: 'scroll'
        }}
      >
        <img
          style={{
            filter: 'brightness(0.95)'
          }}
          alt="error dev report code"
          src={
            `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${this.getErrorString()}`
          }
        />
        <h1 style={{ textAlign: 'center', color: '#1378AB', marginBottom: 8 }}>È avvenuto un errore imprevisto.</h1>
        <p style={{ textAlign: 'center', marginTop: 8, marginBottom: 48 }}>
          Ci scusiamo per il disagio, un report sull'errore è già stato inviato al nostro team.<br/>
          Assicurati di aver installato l'ultimo aggiornamento per il tuo browser o per il tuo sistema.
        </p>
        <button
          onClick={this.reloadPage.bind(this)}
          style={{
            marginBottom: 32,
            ...buttonStyle
          }}
        >
          Riavvia applicazione
        </button>
        <button
          onClick={this.restoreApp.bind(this)}
          style={buttonStyle}
        >
          Ripristina applicazione allo stato iniziale<br/>(PERDERAI TUTTI I DATI)
        </button>
      </div>;
    }
    return this.props.children; 
  }
}