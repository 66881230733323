import { Stringified } from "../../app/utils";
import APIError from "../api/APIError";
import { handleFetchError } from "../api/request";
import { RoomManager, ServerRoomId } from "../communication/CommunicationAPI";
import configuration from "../configuration/Configuration";

export async function registerToken(
  token: string, identifier: string, sessionToken: string
): Promise<void> {
  const host = configuration.notificationsApiHost;
  const response = await fetch(
    `${host}/device/${identifier}`,
    {
      method: 'PUT',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify({firebaseToken: token})
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
  await response.json();
}

type BaseNotificationData = {
  target: string;
}
type FilesNotificationData = BaseNotificationData & {
  target: 'files';
  location: string;
}
type ChatNotificationData = BaseNotificationData & {
  target: 'chat';
  room: Stringified<ServerRoomId>;
}
export type NotificationData = FilesNotificationData | ChatNotificationData;

export function getURLRedirectFromData(data: NotificationData): string | null{
  const { target } = data;
  switch(target){
    case 'files':
      const { location } = data;
      return `/project/${location}`;
    case 'chat':
      const { room } = data;
      const serverRoomId = JSON.parse(room) as ServerRoomId;
      const roomId = RoomManager.generateRoomId(serverRoomId);
      let path = '';
      const projectUser = (serverRoomId instanceof Array) ?
        serverRoomId.find(partecipant => partecipant.startsWith('project:')) :
        ( serverRoomId.startsWith('project:') ? serverRoomId : null );
      if(projectUser){
        const projectId = projectUser.substring(8);
        path = `/project/${projectId}`;
      }
      if(typeof serverRoomId === "string" && serverRoomId.startsWith('project'))
        return `${path}/chat/project`;
      else
        return `${path}/chat/${roomId.substring(roomId.indexOf(':') + 1)}`;
    default:
      break;
  }
  return null;
}