import { Preferences } from "@capacitor/preferences"

export const CapacitorStorage = {
  getItem: async (key: string): Promise<string | null> => {
    const result = await Preferences.get({ key })
    return result.value
  },
  setItem: (key: string, item: string): Promise<void> => {
    return Preferences.set({ key, value: item })
  },
  removeItem: (key: string): Promise<void> => {
    return Preferences.remove({ key })
  }
}