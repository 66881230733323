import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useCallback, useEffect, useRef, useState } from 'react';
import lame from 'lamejs';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAudioRecorder = () => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [recordStartTime, setRecordStartTime] = useState<number>();
  const [isRecording, setIsRecording] = useState(false);
  const [data, setData] = useState<string | ArrayBuffer | null>(null);
  const recorder = useRef<MediaRecorder | undefined>();
  const timer = useRef<number>();
  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          setIsAvailable(
            devices.some(device => device.kind === "audioinput")
          )
        })
        .catch(() => setIsAvailable(false))
    } else {
      setIsAvailable(false)
    }
  }, []);
  const stop = useCallback(async () => {
    if (timer.current) clearTimeout(timer.current)
    if (recorder.current) {
      recorder.current?.stop();
      recorder.current = undefined
    }
    setIsRecording(false);
    setRecordStartTime(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorder])

  const record = useCallback(async () => {
    if(isAvailable && !isRecording) {
      try{
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        const options = MediaRecorder.isTypeSupported("audio/mp4")
          ? { mimeType: "audio/mp4" }
          : undefined
        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorder.ondataavailable = async (e) => {
          if (e.data && e.data.size > 0) {
            let blob = new Blob([e.data], { type: mediaRecorder.mimeType });
            if (blob.type !== "audio/mp4") {
              blob = await convertToMp3(blob)
            }
            const reader = new FileReader();
            reader.onloadend =
              () =>
                (reader.result && reader.result !== "data:") &&
                setData(reader.result)
            reader.readAsDataURL(blob);
          }
        };
        mediaRecorder.start();
        setIsRecording(true);
        setRecordStartTime(Date.now());
        timer.current = window.setTimeout(stop, 30000)
        recorder.current = mediaRecorder
      } catch {
        setIsAvailable(false)
        setIsRecording(false);
        setRecordStartTime(undefined);
        recorder.current = undefined;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable, isRecording])
  const cancel = useCallback(async () => {
    if (timer.current) clearTimeout(timer.current)
    if (recorder.current) {
      recorder.current.ondataavailable = () => {}
      recorder.current.stop()
      recorder.current = undefined
    }
    setData(null)
    setIsRecording(false);
    setRecordStartTime(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorder])
        
  return { isAvailable, record, stop, cancel, clear: cancel, data, isRecording, recordStartTime };
};

function convertToMp3(original: Blob): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    const context = new AudioContext()
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const buffer = fileReader.result
      if (buffer instanceof ArrayBuffer)
        context.decodeAudioData(buffer, (audioBuffer) => {
          const mp3Blob = audioBufferToMp3(audioBuffer);
          if(mp3Blob) resolve(mp3Blob);
          else reject(new Error("Invalid audio"))
        });
    };
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsArrayBuffer(original);
  });
}

function audioBufferToMp3(aBuffer: AudioBuffer) {
  let numOfChan = aBuffer.numberOfChannels,
    btwLength = aBuffer.length * numOfChan * 2 + 44,
    btwArrBuff = new ArrayBuffer(btwLength),
    btwView = new DataView(btwArrBuff),
    btwChnls = [],
    btwIndex,
    btwSample,
    btwOffset = 0,
    btwPos = 0;
  setUint32(0x46464952); // "RIFF"
  setUint32(btwLength - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"
  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(aBuffer.sampleRate);
  setUint32(aBuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit
  setUint32(0x61746164); // "data" - chunk
  setUint32(btwLength - btwPos - 4); // chunk length

  for (btwIndex = 0; btwIndex < aBuffer.numberOfChannels; btwIndex++)
    btwChnls.push(aBuffer.getChannelData(btwIndex));

  while (btwPos < btwLength) {
    for (btwIndex = 0; btwIndex < numOfChan; btwIndex++) {
      // interleave btwChnls
      btwSample = Math.max(-1, Math.min(1, btwChnls[btwIndex][btwOffset])); // clamp
      btwSample =
        (0.5 + btwSample < 0 ? btwSample * 32768 : btwSample * 32767) | 0; // scale to 16-bit signed int
      btwView.setInt16(btwPos, btwSample, true); // write 16-bit sample
      btwPos += 2;
    }
    btwOffset++; // next source sample
  }

  let wavHdr = lame.WavHeader.readHeader(new DataView(btwArrBuff));

  if (!wavHdr) return;

  //Stereo
  let data = new Int16Array(btwArrBuff, wavHdr.dataOffset, wavHdr.dataLen / 2);
  let leftData = [];
  let rightData = [];
  for (let i = 0; i < data.length; i += 2) {
    leftData.push(data[i]);
    rightData.push(data[i + 1]);
  }

  return wavToMp3(wavHdr.channels, wavHdr.sampleRate, data);

  function setUint16(data: number) {
    btwView.setUint16(btwPos, data, true);
    btwPos += 2;
  }

  function setUint32(data: number) {
    btwView.setUint32(btwPos, data, true);
    btwPos += 4;
  }
}

function wavToMp3(channels: number, sampleRate: number, left: Int16Array) {
  var buffer = [];
  var mp3enc = new lame.Mp3Encoder(channels, sampleRate, 128);
  var remaining = left.length;
  var samplesPerFrame = 1152;

  for (var i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
    var mono = left.subarray(i, i + samplesPerFrame);
    var mp3buf = mp3enc.encodeBuffer(mono);
    if (mp3buf.length > 0) {
      buffer.push(mp3buf); //new Int8Array(mp3buf));
    }
    remaining -= samplesPerFrame;
  }
  var d = mp3enc.flush();
  if (d.length > 0) {
    buffer.push(new Int8Array(d));
  }

  var mp3Blob = new Blob(buffer, { type: "audio/mp3" });
  return mp3Blob;
}
