import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import rootReducer from '../features/rootSlice';
import logger from 'redux-logger';
import { chatMiddleware } from '../features/communication/CommunicationSlice';

export const store = configureStore({
  reducer: rootReducer,
  middleware:
    (getDefaultMiddleware) => 
      getDefaultMiddleware({ serializableCheck: false })
      .concat(
        (process.env.NODE_ENV !== 'production') ?
        [chatMiddleware, logger] :
        [chatMiddleware]
      ),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
