import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory } from '@capacitor/filesystem';
import FileSaver from "file-saver";
import { Base64 } from "js-base64";
import mime from 'mime';

export function isDocumentFile(filename: string){
  const fileExtension = filename.split(".").pop()?.toLowerCase();
  return fileExtension && [
    "odt", "ods", "odp", "rtf", "docx", "doc", "ppt", "pptx", "xls", "xlsx"
  ].indexOf(fileExtension) > -1;
}

export function generateDocumentViewer(link: string){
  const fileExtension = link.split(".").pop()?.toLowerCase();
  let destination;
  if(
    fileExtension &&
    [
      "odt", "ods", "odp", "rtf", "docx", "doc", "ppt", "pptx", "xls", "xlsx"
    ].indexOf(fileExtension) > -1
  ){
    destination = `https://view.officeapps.live.com/op/embed.aspx?src=${link}`;
  }else{
    destination = `http://docs.google.com/gview?embedded=true&url=${link}`;
  }
  return destination;
}

function isMobile(): boolean{
  const userAgent = navigator.userAgent.toLowerCase();
  return (userAgent.indexOf("android") > -1
         || userAgent.indexOf("webos") > -1
         || userAgent.indexOf("iphone") > -1
         || userAgent.indexOf("ipad") > -1
         || userAgent.indexOf("ipod") > -1
         || userAgent.indexOf("blackberry") > -1
         || userAgent.indexOf("windows phone") > -1)
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  return window.btoa(
      Array.from(new Uint8Array(buffer)).map(function (byte) {
        return String.fromCharCode(byte);
      }).join("")
  );
}

export async function fileExists(path: string) {
  try {
    const directory = Directory.Documents;
    await Filesystem.stat({path, directory});
    return true;
  } catch (error) {
    return false;
  }
}

export async function downloadLink(link: string, filename: string){
  FileSaver.saveAs(link, filename);
}

export class FixedQueue<T>{
  size: number;
  queue: T[];

  constructor(size: number, queue: T[] = []){
    this.size = size;
    this.queue = queue;
  }
  includes(element: T){
    return this.queue.includes(element);
  }
  push(element: T){
    let newQueue = [...this.queue];
    if(this.queue.length >= this.size)
      newQueue = newQueue.slice(1);
    newQueue.push(element);
    return new FixedQueue<T>(this.size, newQueue);
  }
  find(fn: (currentElement: T) => boolean){
    return this.queue.find(fn);
  }
  remove(fn: (currentElement: T) => boolean){
    this.queue = this.queue.filter(fn);
  }
  getLast(): T{
    return this.queue[this.queue.length - 1];
  }
}

export function isEmbed(): boolean {
  const query = new URLSearchParams(
    window.location.search?.substring(1)
  );
  return query.get('embed') !== null;
}

function stringCRC16(buffer: string): string {
  let crc = 0xFFFF;
  let odd;
  for(var i = 0; i < buffer.length; i++){
    crc = crc ^ buffer.charCodeAt(i);
    for(var j = 0; j < 8; j++){
      odd = crc & 0x0001;
      crc = crc >> 1;
      if(odd){
        crc = crc ^ 0xA001;
      }
    }
  }
  return String.fromCharCode(crc);
};

export function simpleArrayHash(plain: string[]){
  const compacted = new Uint16Array(plain.reduce(
    (accumulator: number[], current) => {
      const merged: number[] = [];
      const expanded = current + stringCRC16(current);
      for(let i = 0; i < expanded.length; i++){
        let currentCharacter = expanded.charCodeAt(i);
        let accumulatorCharacter = accumulator[i] || 0;
        merged[i] = accumulatorCharacter ^ currentCharacter;
      }
      for(let i = expanded.length; i < accumulator.length; i++){
        merged[i] = accumulator[i];
      }
      return merged;
    },
    []
  ));
  return Base64.fromUint8Array(
    new Uint8Array(compacted.buffer, compacted.byteOffset, compacted.byteLength),
    true
  );
}

export function hexToBase64(hex: string): string{
  return Base64.fromUint8Array(
    hexToArrayBuffer(hex),
    true
  );
}

const twoCharPatter = /.{1,2}/g;

function hexToArrayBuffer(hex: string): Uint8Array {
  return Uint8Array.from(hex.match(twoCharPatter)?.map((byte) => parseInt(byte, 16)) || []);
}

export function isMedia(filename: string){
  const type = mime.getType(filename);
  return type && (type.startsWith('image/') || type.startsWith('video/'));
}

export function isImage(filename: string){
  const type = mime.getType(filename);
  return type && type.startsWith('image/');
}

export function isVideo(filename: string){
  const type = mime.getType(filename);
  return (type && type.startsWith('video/')) || isStream(filename);
}

export function isStream(filename: string){
  const type = mime.getType(filename);
  return type === "application/vnd.apple.mpegurl";
}

export function generateObjectURLFromBlob(blob: Blob) {
  var urlCreator = window.URL || window.webkitURL;
  var imageUrl = urlCreator.createObjectURL(blob);
  return imageUrl;
}

export function dataURIToBlob(dataURI: string) {
  const bytes = Base64.toUint8Array(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const blob = new Blob([bytes], {type: mimeString});
  return blob;
}

type MediaSizes = {
  height: number;
  width: number;
}

function calculateThumbnailSizes(sizes: MediaSizes, maxSize?: number): MediaSizes{
  maxSize = maxSize || 256;
  const bigSize = Math.max(sizes.width, sizes.height);
  const width = sizes.width / bigSize * maxSize;
  const height = sizes.height / bigSize * maxSize;
  return { width, height };
}

export async function generateVideoThumbnail(file: File): Promise<Blob | null>{
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    video.autoplay = true;
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);
    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;
    video.play();

    video.oncanplay = () => {
      video.currentTime = 0.1;
      video.oncanplay = null;
    };
    video.onseeked = () => {
      const ctx = canvas.getContext('2d');

      const { height: resultHeight, width: resultWidth } =
        calculateThumbnailSizes(
          {height: video.videoHeight, width: video.videoWidth}
        );
      canvas.width = resultWidth;
      canvas.height = resultHeight;

      ctx?.drawImage(
        video, 0, 0, video.videoWidth, video.videoHeight,
        0, 0, resultWidth, resultHeight
      );
      video.pause();
      canvas.toBlob(blob => resolve(blob));
    };
    video.onerror = (event, _, __, ___, error) => {
      reject(error || new Error(event.toString()));
    };
  });
};

export async function generateImageThumbnail(file: File, size: number): Promise<Blob | null>{
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function(){
      const canvas = document.createElement('canvas');
      const { height, width } =
        calculateThumbnailSizes({height: img.height, width: img.width}, size);
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(
        img, 0, 0, img.width, img.height, 0, 0, width, height
      );
      canvas.toBlob(blob => resolve(blob));
    }
    img.src = URL.createObjectURL(file);
  });
}

export const isWeb = () => {
  return Capacitor.getPlatform() === 'web';
}

export const isNative = () => {
  return Capacitor.isNativePlatform();
}

export const isAndroid = () => {
  return Capacitor.getPlatform() === "android"
  || (navigator.userAgent.toLowerCase().includes("android"))
}

export const isSafari = () => {
  if(
    (
      navigator.userAgent.indexOf('Chrome') <= 0 &&
      navigator.userAgent.indexOf('Safari') >= 0
    ) ||
    (
      navigator.userAgent.indexOf('Mac') >= 0 &&
      ("ontouchend" in document)
    )
  )
    return true;
  switch(navigator.platform){
    case "iPad Simulator":
    case "iPhone Simulator":
    case "iPod Simulator":
    case "iPad":
    case "iPhone":
    case "iPod":
      return true;
  }
  return false;
}

export const isIOS = () => {
  return Capacitor.getPlatform() === "ios" ||
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const isApple = () => {
  return Capacitor.getPlatform() === "ios" ||
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
    'Mac'
  ].includes(navigator.platform)
  || (navigator.platform.includes("Mac"))
  || (navigator.userAgent.includes("Mac"))
}

type Routine = () => Promise<any>;

export class SequentialQueue {
  static instance: SequentialQueue;
  queue: Routine[];
  running: boolean;
  constructor(){
    this.queue = [];
    this.running = false;
  }
  static getInstance(){
    if(!this.instance)
      this.instance = new SequentialQueue();
    return this.instance;
  }
  async processQueue(){
    this.running = true;
    while(this.queue.length > 0){
      const routine = this.queue.shift();
      if(routine)
        await routine();
    }
    this.running = false;
  }
  add(routine: Routine){
    this.queue.push(routine);
    if(!this.running) this.processQueue();
  }
}

export type Stringified<T> = string & {
  [P in keyof T]: { "_ value": T[P] }
};

export async function checkWritePermissions(){
  let fsPermission = await Filesystem.checkPermissions();
  if(fsPermission.publicStorage !== 'granted')
    fsPermission = await Filesystem.requestPermissions();
  if(fsPermission.publicStorage !== 'granted')
    throw new Error('Permesso di scrittura file negato');
}

export async function writeBlobToFile(path: string, directory: Directory, blob: Blob){
  await Filesystem.writeFile({
    path,
    data: '',
    directory,
    recursive: true
  });
  let offset = 0;
  const chunk_size = 3 * 128 * 1024;
  while(offset < blob.size){
    const chunk_blob = blob.slice(offset, offset + chunk_size);
    const buffer = await chunk_blob.arrayBuffer();
    await Filesystem.appendFile({
      directory,
      path,
      data: arrayBufferToBase64(buffer)
    });
    offset += chunk_size;
  }
}

export function findLast<T>(array: Array<T> | undefined, predicate: (value: T, index: number, obj: T[]) => boolean): T | undefined {
  if (array) {
    let l = array.length;
    while (l--) {
      if (predicate(array[l], l, array))
        return array[l];
    }
  }
  return undefined;
}