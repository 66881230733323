import { Button, Fade, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { styled } from '@mui/system'
import { useSnackbar } from 'notistack'

import logo from '../resources/images/logo.png'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { getError, isAuthenticated, isLoading, login } from '../features/account/AccountSlice'
import { FormEvent, useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const Logo = styled('img')({
  width: 200,
  marginBottom: 40
})

export default function Login(){

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [uncoverPassword, setUncoverPassword] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const logged = useAppSelector(isAuthenticated);
  const loading = useAppSelector(isLoading);
  const userError = useAppSelector(getError);
  const dispatch = useAppDispatch();

  const logUser = (event: FormEvent) => {
    event.preventDefault();
    dispatch(login({email, password}));
  };

  useEffect(
    () => {
      if(userError){
        enqueueSnackbar(
          userError.message,
          {variant: "error", preventDuplicate: true}
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userError]
  )

  if(logged)
    return <Navigate replace to="/" />

  return <Grid
    container justifyContent="center"
    sx={{
      width: 1, height: '100vh', background: '#f2f2f2'
    }}
  >
    <Fade in={true} exit={true} mountOnEnter unmountOnExit>
    <Grid
      item
      xs={8}
      sm={6}
      md={4}
      lg={3}
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
      component="form"
      onSubmit={logUser}
    >
      <Grid item container justifyContent="center">
        <Logo src={logo} alt="logo" />
      </Grid>
      <Grid item>
        <TextField
          type="email"
          label="Indirizzo e-mail"
          variant="standard"
          fullWidth
          value={email}
          onChange={event => setEmail(event.target.value)}
          disabled={loading}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          type={uncoverPassword ? "text" : "password"}
          label="Password"
          variant="standard"
          fullWidth
          value={password}
          onChange={event => setPassword(event.target.value)}
          disabled={loading}
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">
              {
                uncoverPassword ?
                <IconButton onClick={() => setUncoverPassword(false)}>
                  <VisibilityOff />
                </IconButton> :
                <IconButton onClick={() => setUncoverPassword(true)}>
                  <Visibility />
                </IconButton>
              }
            </InputAdornment>,
          }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          fullWidth
          disabled={loading}
          type="submit"
        >
          Accedi
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="text"
          color="primary"
          fullWidth
          onClick={() => navigate('/recover')}
          disabled={loading}
        >
          Password dimenticata?
        </Button>
      </Grid>
      <Button
        variant="text" size="small" color="primary"
        sx={{
          position: 'fixed', bottom: theme => theme.spacing(1),
          left: 0, right: 0, margin: 'auto', width: 'fit-content',
        }}
        component={Link}
        to="/privacy"
      >
        Privacy Policy
      </Button>
    </Grid>
    </Fade>
  </Grid>
}