import { Button, Grid, Typography, useTheme } from "@mui/material";
import { isWeb } from "../app/utils";
import { useMemo } from "react";
import Logo from "../components/Logo";
import { AppUpdate } from "@capawesome/capacitor-app-update";

export default function ForceUpdate(){
  const web = useMemo(() => isWeb(), [])
  const theme = useTheme()

  function goToUpdate() {
    AppUpdate.openAppStore()
  }

  return <Grid
    container
    justifyContent="center"
    alignItems="center"
    sx={{
      width: 1, minHeight: '100vh',
      height: web ? '100vh' : 1,
      background: '#f2f2f2'
    }}
  >
    <Grid item sx={{ textAlign: 'center', marginTop: -10 }}>
      <Logo height={theme.spacing(8)}/>
      <Typography variant="h4" mt={2} gutterBottom>
        Aggiornamento disponibile
      </Typography>
      <Typography variant="body1" paragraph>
        È disponibile una nuova versione dell'applicazione. Si prega di aggiornare per continuare.
      </Typography>
      <Button variant="contained" color="primary" onClick={goToUpdate}>
        Aggiorna ora
      </Button>
    </Grid>
  </Grid>
}
