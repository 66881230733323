export default class APIError extends Error{
  static async build(response: Response){
    let message;
    try {
      const content = await response.json();
      if(content.error){
        if(typeof content.error === "string")
          message = content.error;
        else if(content.error.msg)
          message = content.error.msg;
      }else if(content.errors){
        message = content.errors.map((error: any) => error.msg).join('\n');
      }
    } catch {}
    if(!message)
      switch(response.status){
        case 400:
          message = "Richiesta non conforme";
          break;
        case 401:
          message = "Utente non autorizzato";
          break;
        case 403:
          message = "Operazione non consentita";
          break;
        case 404:
          message = "Oggetto non trovato";
          break;
        case 409:
          message = "L'operaizone genera un conflitto";
          break;
        case 429:
          message = "Troppe richieste al server";
          break;
        case 440:
        case 441:
          message = "Progetto protetto da password";
          break;
        case 520:
          message = "Operazione fallita";
          break;
        case 500:
          message = "Errore interno";
          break;
        default:
          message = response.statusText
          break;
      }
    return new this(message);
  }
}