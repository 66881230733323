import { Button, Snackbar, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { isNative } from '../app/utils';
import { hasAskedForCookies, showedCookiesBanner } from '../features/ui/UISlice'


export default function CookieBanner(){
  const { pathname } = useLocation();
  const askedForCookies = useAppSelector(hasAskedForCookies);
  const native = useMemo(() => isNative(), []);
  const dispatch = useAppDispatch();

  if(askedForCookies || pathname === '/privacy' || native)
    return <></>

  function disposeDialog(){
    dispatch(showedCookiesBanner());
  }

  return <Snackbar
    open={true}
    message={<Typography gutterBottom>
      Raccogliamo solo i dati necessari a far funzionare la nostra piattaforma. Per i dettagli sui cookie e sulle tecnologie che utilizziamo puoi leggere la nostra <Link to="/privacy">privacy policy</Link>.
    </Typography>}
    action={<Button variant="outlined" color="primary" size="small" onClick={disposeDialog}>
      Ok
    </Button>}
    sx={{
      "& .MuiPaper-root": {
        backgroundColor: theme => theme.palette.background.paper,
        color: 'text.primary'
      },
    }}
  />
}