import configuration from "../configuration/Configuration";

export async function reportCrash(stacktrace: string): Promise<void>{
  const host = configuration.mailsApiHost;
  const body = {
    stacktrace
  };
  await fetch(
    `${host}/stacktrace`,
    {
      method: "PUT",
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }
  );
}