import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import './index.css';
import SplashScreen from './pages/SplashScreen';
import { register } from './serviceWorker';
import AppErrorBoundary from './components/errorBoundaries/AppErrorBoundary';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/it';

const container = document.getElementById('root')!;
const root = createRoot(container);

let theme = createTheme({
  palette: {
    primary: {
      main: '#1378AB',
    },
    secondary: {
      main: 'rgba(255, 255, 255, 0.89)',
    },
    background: {
      default: '#d1ecfa',
    },
    text: {
      primary: 'rgb(19 120 171 / 89%)',
    }
  },
  transitions: {
    duration: {
      enteringScreen: 500,
    },
  },
})
theme = responsiveFontSizes(theme)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<SplashScreen />} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <AppErrorBoundary>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                <App />
              </LocalizationProvider>
            </AppErrorBoundary>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

register();