type AppConfiguration = {
  webappHost: string;
  seafileHost: string;
  mailsApiHost: string;
  seafileExtApiHost: string;
  notificationsApiHost: string;
  chatsWsHost: string;
  chatsWsPath?: string;
}

const configuration: AppConfiguration = {
  webappHost: process.env.REACT_APP_WEBAPP_HOST || '',
  seafileHost: process.env.REACT_APP_SEAFILE_HOST || '',
  seafileExtApiHost: process.env.REACT_APP_SEAFILE_EXT_API_HOST || '',
  mailsApiHost: process.env.REACT_APP_MAILS_API_HOST || '',
  notificationsApiHost: process.env.REACT_APP_NOTIFICATIONS_API_HOST || '',
  chatsWsHost: process.env.REACT_APP_CHATS_WS_HOST || '',
  chatsWsPath: process.env.REACT_APP_CHATS_WS_PATH,
}

export default configuration;