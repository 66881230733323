import { createAsyncThunk, createSlice, isAnyOf, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { changePassword as changePasswordAPI, getUser as getUserAPI, editMyPhone as editMyPhoneAPI, editMyEmail as editMyEmailAPI, login as loginAPI, getMe as getMeAPI, sendRecoverEmail as sendRecoverEmailAPI, UserDetails, getStatus as getStatusAPI, ServerStatus } from './AccountAPI';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface AccountState {
  sessionToken: string | null;
  details: UserDetails | null;
  status: 'idle' | 'loading' | 'failed';
  error: Error | null;
  loadedAccounts: Record<string, UserDetails>;
  serverStatus: ServerStatus
}

const initialState: AccountState = {
  sessionToken: null,
  details: null,
  status: 'idle',
  error: null,
  loadedAccounts: {},
  serverStatus: "unknown"
};

type LoginOptions = {
  email: string;
  password: string;
}
export const login = createAsyncThunk(
  'account/login',
  async ({email, password}: LoginOptions, { rejectWithValue }) => {
    try{
      const response = await loginAPI(email, password);
      return response.token;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);
export const testServerStatus = createAsyncThunk(
  'account/test_server_status',
  async (_, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Utente non autenticato");
      const response = await getStatusAPI(sessionToken);
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);
export const getMe = createAsyncThunk(
  'account/get_me',
  async (_, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Utente non autenticato");
      const response = await getMeAPI(sessionToken);
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);
export const getUser = createAsyncThunk(
  'account/get_user',
  async (email: string, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Utente non autenticato");
      const alreadyLoaded = getLoadedUser(email)(state);
      if(!alreadyLoaded){
        const response = await getUserAPI(email, sessionToken);
        return response;
      }else
        return alreadyLoaded;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);
export const sendRecoverEmail = createAsyncThunk(
  'account/send_recover_email',
  async (email: string, { rejectWithValue }) => {
    try{
      const response = await sendRecoverEmailAPI(email);
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

type ChangePasswordRequest = {
  email: string;
  newPassword: string;
}

export const changePassword = createAsyncThunk(
  'account/change_password',
  async (
    {email, newPassword}: ChangePasswordRequest,
    { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Utente non autenticato");
      const me = getLoggedAccount(state);
      if(!me) throw new Error("Utente non ancora caricato");
      await changePasswordAPI(newPassword, me.email, sessionToken);
      const response = await dispatch(login({email, password: newPassword}))
        .then(unwrapResult)
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);
export const editMyPhone = createAsyncThunk(
  'account/edit_my_phone',
  async (phone: string, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Utente non autenticato");
      const me = getLoggedAccount(state);
      if(!me) throw new Error("Utente non ancora caricato");
      const response = await editMyPhoneAPI(phone, me.email, sessionToken);
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);
export const editMyEmail = createAsyncThunk(
  'account/edit_my_email',
  async (email: string, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Utente non autenticato");
      const me = getLoggedAccount(state);
      if(!me) throw new Error("Utente non ancora caricato");
      const response = await editMyEmailAPI(email, me.email, sessionToken);
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);
export const logout = createAsyncThunk(
  'account/logout',
  async () => {
    return;
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    injectSession: (state, action: PayloadAction<string>) => {
      state.sessionToken = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state) => {
        state.status = 'idle';
        state.sessionToken = null;
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<UserDetails>) => {
        state.status = 'idle';
        state.loadedAccounts[action.payload.email] = action.payload;
      })
      .addCase(testServerStatus.fulfilled, (state, action: PayloadAction<ServerStatus>) => {
        state.serverStatus = action.payload;
      })
      .addCase(testServerStatus.pending, (state) => {
        state.serverStatus = "unknown";
      })
      .addMatcher(
        isAnyOf(login.fulfilled, changePassword.fulfilled),
        (state, action: PayloadAction<string>) => {
          state.status = 'idle';
          state.sessionToken = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(getMe.fulfilled, editMyPhone.fulfilled, editMyEmail.fulfilled),
        (state, action: PayloadAction<UserDetails>) => {
          state.status = 'idle';
          state.details = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(
          login.pending, getMe.pending, sendRecoverEmail.pending, editMyPhone.pending,
          editMyEmail.pending, getUser.pending, changePassword.pending,
          testServerStatus.pending
        ),
        (state) => {
          state.status = 'loading';
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(login.rejected, getMe.rejected),
        (state) => {
          state.sessionToken = null;
        }
      )
      .addMatcher(
        isAnyOf(
          login.rejected, getMe.rejected, sendRecoverEmail.rejected, editMyPhone.rejected,
          editMyEmail.rejected, getUser.rejected, testServerStatus.rejected,
          changePassword.rejected
        ),
        (state, action) => {
          state.status = 'failed';
          const error = action.payload;
          state.error = (error instanceof Error) ? error : new Error(error as string);
        }
      );
  },
});

export const { injectSession } = accountSlice.actions;

export const isAuthenticated = (state: RootState) => state.account.sessionToken !== null;
export const getSessionToken = (state: RootState) => state.account.sessionToken;
export const isLoading = (state: RootState) => state.account.status === 'loading';
export const getError = (state: RootState) => state.account.error;
export const getLoggedAccount = (state: RootState) => state.account.details;
export const getLoadedUser =
  (email: string) =>
    (state: RootState): UserDetails | undefined => state.account.loadedAccounts[email];
    export const getServerStatus = (state: RootState) => state.account.serverStatus;

const persistConfig = {
  key: 'account',
  storage,
  whitelist: ['sessionToken']
};

export default persistReducer(persistConfig, accountSlice.reducer);
