import { FirebaseOptions } from 'firebase/app';
import APIError from "../api/APIError";
import { handleFetchError } from "../api/request";
import configuration from "../configuration/Configuration";
import { Capacitor } from '@capacitor/core';

export type FirebaseConfig = FirebaseOptions & { vapidKey?: string };

export type ServerSettings = {
  assistance_phone_number: string;
  assistance_ref: string;
  firebase_config: Record<'web' | 'android' | 'ios', FirebaseConfig>;
  max_upload_size?: number;
}

export type Settings = {
  assistancePhoneNumber: string;
  assistanceRef: string;
  firebaseConfig: Record<'web' | 'android' | 'ios', FirebaseConfig>;
  maxUploadSize: number;
}

export async function loadSettings(): Promise<Settings>{
  const host = configuration.seafileExtApiHost;
  const extResponse = await fetch(
    `${host}/settings/`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!extResponse.ok)
    throw await APIError.build(extResponse);
  const serverResponse: ServerSettings = await extResponse.json();
  return {
    assistancePhoneNumber: serverResponse.assistance_phone_number,
    assistanceRef: serverResponse.assistance_ref,
    firebaseConfig: serverResponse.firebase_config,
    maxUploadSize: serverResponse.max_upload_size || 100000
  };
}

export type PlatformVersionSettings = {
  minimum?: string
}
type VersionSettings = Record<string, PlatformVersionSettings>

export async function loadVersionSettings(): Promise<PlatformVersionSettings | undefined>{
  const host = configuration.webappHost;
  const response = await fetch(
    `${host}/versions.json`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
  const versions: VersionSettings = await response.json();
  const currentPlatform = Capacitor.getPlatform().toLowerCase()
  const platformVersion: PlatformVersionSettings | undefined = versions[currentPlatform]
  return platformVersion;
}