import { Fade, Stack } from '@mui/material'
import { styled } from '@mui/system'

import logo from '../resources/images/logo.png'

const Logo = styled('img')({
  width: 150
})

export default function SplashScreen(){
  return <Stack
    justifyContent="center"
    alignItems="center"
    sx={{ width: 1, height: '100vh', background: '#f2f2f2' }}
  >
    <Fade in exit timeout={1000}>
      <Logo src={logo} alt="logo" />
    </Fade>
  </Stack>
}