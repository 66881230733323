import { Grid, Fade, Typography, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

import policy from '../resources/privacy-policy.pdf';
import { isWeb } from '../app/utils';
import PDFViewer from '../components/PDFViewer';
import { useMemo } from 'react';

export default function Privacy(){

  const navigate = useNavigate();
  const web = useMemo(() => isWeb(), [])

  return <Grid
    container justifyContent="center"
    sx={{
      width: 1, minHeight: '100vh', height: web ? '100vh' : 1, background: '#f2f2f2'
    }}
  >
    <Fade in exit mountOnEnter unmountOnExit>
    <Grid
      item
      xs={12}
      sm={8}
      md={6}
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      p={3}
      spacing={3}
    >
      <Grid item container justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item>
          <IconButton onClick={() => navigate(-1)} color="primary">
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="text.primary">
            Privacy Policy
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: '1', width: 1 }}>
      {
        web ?
        <iframe
          title="privacy-policy-file"
          src={
            window.location.hostname === 'localhost' ?
            policy :
            `https://docs.google.com/gview?url=${window.location.host}${policy}&embedded=true`
          }
          style={{ width: '100%', height: '100%' }} frameBorder="0"
        >
          <Typography color="text.primary">
            Impossibile leggere il file.
          </Typography>
        </iframe> :
        <PDFViewer url={policy} sx={{ width: 1, height: 1 }} />
      }
      </Grid>
    </Grid>
    </Fade>
  </Grid>
}