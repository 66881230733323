import APIError from "../api/APIError";
import { handleFetchError } from "../api/request";
import configuration from "../configuration/Configuration";

export type Group = {
  ctime: number;
  creator: string;
  id: number;
  name: string;
}

type ServerResponse = {
  groups: Group[];
};

export const getGroups = async (sessionToken: string): Promise<Group[]> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/groups/`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json; indent=4',
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
  const responseContent: ServerResponse = await response.json();
  return responseContent.groups;
}