export function handleFetchError(reason: any): Response{
  const responseContent = new Blob(
    ['{"error": "Impossibile continuare. Assicurati di avere i permessi per proseguire e che la tua connessione sia attiva."}']
  );
  const myOptions = {
    status: 599,
    ok: false,
    statusText: reason
  };
  return new Response(responseContent, myOptions);
}