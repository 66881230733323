import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { loadSettings as loadSettingsAPI, loadVersionSettings, PlatformVersionSettings, Settings } from './SettingsAPI';

export interface SettingsState extends Settings{
  versions: PlatformVersionSettings;
  status: 'idle' | 'loading' | 'failed';
  error: Error | null;
}

const initialState: SettingsState = {
  assistancePhoneNumber: "",
  assistanceRef: "",
  maxUploadSize: 100000,
  firebaseConfig: {
    web: {},
    android: {},
    ios: {}
  },
  versions: {},
  status: 'idle',
  error: null
};

export const loadSettings = createAsyncThunk(
  'settings/load',
  async (_, { rejectWithValue }) => {
    try{
      const response = await loadSettingsAPI();
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const loadVersions = createAsyncThunk(
  'settings/loadVersions',
  async (_, { rejectWithValue }) => {
    try{
      const response = await loadVersionSettings();
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(loadSettings.fulfilled, (state, action: PayloadAction<Settings>) => {
        state.status = 'idle';
        state.assistancePhoneNumber = action.payload.assistancePhoneNumber;
        state.assistanceRef = action.payload.assistanceRef;
        state.firebaseConfig = action.payload.firebaseConfig;
        state.maxUploadSize = action.payload.maxUploadSize;
      })
      .addCase(loadVersions.fulfilled, (state, action: PayloadAction<PlatformVersionSettings | undefined>) => {
        state.status = 'idle';
        state.versions = action.payload ?? {};
      })
      .addMatcher(
        isAnyOf(loadSettings.pending, loadVersions.pending),
        (state) => {
          state.status = 'loading';
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(loadSettings.rejected, loadVersions.pending),
        (state, action) => {
          state.status = 'failed';
          const error = action.payload;
          state.error = (error instanceof Error) ? error : new Error(error as string);
        }
      );
  },
});

export const isLoading = (state: RootState) => state.settings.status === 'loading';
export const getError = (state: RootState) => state.settings.error;
export const getAssistanceRef = (state: RootState) => state.settings.assistanceRef;
export const getAssistancePhoneNumber =
  (state: RootState) => state.settings.assistancePhoneNumber;
export const getFirebaseConfig =
  (state: RootState) => state.settings.firebaseConfig;
export const getMaxUploadSize =
  (state: RootState) => state.settings.maxUploadSize;
export const getMinimumVersion =
  (state: RootState) => state.settings.versions.minimum;

export default settingsSlice.reducer;
