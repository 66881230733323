import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, BoxProps, IconButton } from "@mui/material";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect, useRef } from 'react';

type PDFViewerProps = BoxProps & {
  url: string;
}

export default function PDFViewer({ sx, url }: PDFViewerProps){
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pdfPromise = useMemo(async () => {
    if(!url) return null;
    const pdfJS = await import('pdfjs-dist');
    pdfJS.GlobalWorkerOptions.workerSrc =
      window.location.origin + '/pdf.worker.min.js';
    return await pdfJS.getDocument(url).promise;
  }, [url]);
  const [rendering, setRendering] = useState(false);
  const [lastPage, setLastPage] = useState(false);

	useEffect(() => {
		(async function () {
      const pdf = await pdfPromise;
      if(pdf && canvasRef.current){
        setRendering(true);
        setLastPage(pdf.numPages === pageNumber);
        const page = await pdf.getPage(pageNumber);
        const canvas = canvasRef.current;
        let viewport = page.getViewport({ scale: 2 });
        if(containerRef.current){
          const proportion = viewport.height / viewport.width;
          const width = containerRef.current.clientWidth;
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          canvas.style.height = (proportion * width).toString();
          canvas.style.width = width.toString();
        }
        const canvasContext = canvas.getContext('2d');
        if(canvasContext){
          const renderContext = { canvasContext, viewport };
          const renderTask = page.render(renderContext);
          renderTask.promise.finally(() => setRendering(false));
        }
      }
		})();
	}, [canvasRef, pdfPromise, pageNumber]);
  return <Box
    sx={
      {...(sx || {}),
      position: 'relative', display: 'flex', flexDirection: 'column',
      justifyContent: 'center'
    }}
    ref={containerRef}
  >
    <canvas ref={canvasRef} />
    <IconButton
      disabled={rendering || lastPage}
      color="primary"
      sx={{ position: 'absolute', right: 0, top: 'calc(50% - 20px)' }}
      onClick={() => setPageNumber(pageNumber + 1)}
    >
      <ArrowForwardIos />
    </IconButton>
    <IconButton
      disabled={rendering || pageNumber === 1}
      color="primary"
      sx={{ position: 'absolute', left: 0, top: 'calc(50% - 20px)' }}
      onClick={() => setPageNumber(pageNumber - 1)}
    >
      <ArrowBackIos />
    </IconButton>
  </Box>
}