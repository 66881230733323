import { combineReducers } from "@reduxjs/toolkit";
import accountReducer from './account/AccountSlice';
import projectsReducer from './projects/ProjectsSlice';
import communicationReducer from './communication/CommunicationSlice';
import notificationsReducer from './notifications/NotificationsSlice';
import settingsReducer from './settings/SettingsSlice';
import uiReducer from './ui/UISlice';
import groupsReducer from './groups/GroupsSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'account', 'projects', 'communication', 'notifications', 'groups', 'settings', 'ui'
  ]
};

const rootReducer = combineReducers({
  account: accountReducer,
  projects: projectsReducer,
  communication: communicationReducer,
  notifications: notificationsReducer,
  groups: groupsReducer,
  settings: settingsReducer,
  ui: uiReducer,
})

export default persistReducer(persistConfig, rootReducer)