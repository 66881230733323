import { createAsyncThunk, createSlice, isAnyOf, PayloadAction, Reducer, unwrapResult } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  generateVideoThumbnail,
  isVideo,
  SequentialQueue,
  isWeb,
  fileExists, isMedia, isIOS, checkWritePermissions, writeBlobToFile, isImage, generateImageThumbnail
} from '../../app/utils';
import { getSessionToken, login } from '../account/AccountSlice';
import { getMaxUploadSize } from '../settings/SettingsSlice';
import { viewFile } from '../ui/UISlice';
import {
  loadMine as loadMineAPI,
  loadProjectMembers as loadProjectMembersAPI,
  loadRepo as loadRepoAPI,
  getOpenLink as getOpenLinkAPI,
  downloadFile as downloadFileAPI,
  uploadChunk as uploadChunkAPI,
  getFileThumbnail as getFileThumbnailAPI,
  getFile as getFileAPI,
  deleteFile as deleteFileAPI,
  getPersonalLibrary as getPersonalLibraryAPI,
  setCustomThumbnailToFile as setCustomThumbnailToFileAPI,
  generateFileDownloadLink as generateFileDownloadLinkAPI,
  generateFilePreview as generateFilePreviewAPI,
  renameFile as renameFileAPI,
  Project,
  ProjectMember,
  ProjectEntity,
  UploadOptions,
  ParsingType,
  ThumbnailOptions,
  RepoId,
  UploadedFile,
  ChunkUploadOptions,
} from './ProjectsAPI';
import {Directory, Filesystem} from "@capacitor/filesystem";
import { Media } from '@capacitor-community/media';
import { createTransform, persistReducer } from 'redux-persist';
import { Capacitor } from '@capacitor/core';
import { CapacitorStorage } from '../../app/persist';

type BaseUploadStatus = {
  uploadedBytes: number;
  totalBytes: number;
  error?: Error;
  state: 'uploading' | 'canceled';
}

type BaseDownloadStatus = {
  downloadedBytes: number;
  totalBytes: number;
  error?: Error;
  state: 'downloading' | 'canceled';
}

type UploadingStatus = BaseUploadStatus & {
  state: 'uploading';
  controller?: AbortController;
}

export type DownloadingStatus = BaseDownloadStatus & {
  state: 'downloading';
  controller?: AbortController;
}

type UploadCanceledStatus = BaseUploadStatus & {
  state: 'canceled';
  data?: {
    repo: RepoId;
    folder: string;
    file: File;
    options?: UploadOptions;
  };
}

export type DownloadCanceledStatus = BaseDownloadStatus & {
  state: 'canceled';
  data?: {
    repo: RepoId;
    folder: string;
    entity: ProjectEntity;
    options?: UploadOptions;
  };
}

type CanceledStatus = UploadCanceledStatus | DownloadCanceledStatus;

export function isCanceledStatus(status: UploadStatus | DownloadStatus): status is CanceledStatus{
  return status.state === 'canceled';
}

export function isUploadingStatus(status: UploadStatus): status is UploadingStatus{
  return status.state === 'uploading';
}

export type UploadStatus = UploadingStatus | UploadCanceledStatus;

export type DownloadStatus = DownloadingStatus | DownloadCanceledStatus;

export function isDownloadingStatus(status: DownloadStatus): status is DownloadingStatus{
  return status.state === 'downloading';
}

export interface ProjectsState {
  downloadedFiles: Record<string, any>;
  loadedThumbnails: Record<string, { thumbnail: string, timestamp: number, transient?: boolean}>;
  loadedFolder: Record<string, ProjectEntity[]>;
  loadedDetails: Record<string, ProjectEntity>,
  loadedProjectMembers: Record<string, ProjectMember[]>;
  personalLib: RepoId | null;
  mine: Project[] | null;
  currentUploads: Record<string, UploadStatus>;
  currentDownloads: Record<string, DownloadStatus>;
  entityToRename: FileIdentity | null;
  status: 'idle' | 'loading' | 'failed';
  error: Error | null;
  downloadLinks: Record<string, {url: string, timestamp: number}>
  previewLinks: Record<string, {url: string, timestamp: number}>
}

const initialState: ProjectsState = {
  downloadedFiles: {},
  loadedThumbnails: {},
  loadedFolder: {},
  loadedDetails: {},
  loadedProjectMembers: {},
  personalLib: null,
  mine: null,
  currentUploads: {},
  currentDownloads: {},
  entityToRename: null,
  status: 'idle',
  error: null,
  downloadLinks: {},
  previewLinks: {}
};

async function loadMineProjects(force: boolean, state: RootState): Promise<Project[]>{
  const sessionToken = getSessionToken(state);
  if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
  if(!force){
    const alreadyLoaded = getMine(state);
    if(alreadyLoaded) return alreadyLoaded;
  }
  const response = await loadMineAPI(sessionToken);
  return response;
}

export const loadMine = createAsyncThunk(
  'projects/load_mine',
  async (_, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      return await loadMineProjects(false, state);
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const loadMineForcely = createAsyncThunk(
  'projects/load_mine_forcely',
  async (_, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      return await loadMineProjects(true, state);
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

type loadProjectMembersResult = {
  projectId: string;
  members: ProjectMember[];
}

export const loadProjectMembers = createAsyncThunk<loadProjectMembersResult, string>(
  'projects/load_project_member',
  async (projectId, { rejectWithValue, getState, dispatch }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === projectId);
      if(!project) throw new Error("Progetto non trovato.");
      const response = await loadProjectMembersAPI(
        project.folders.map(folder => folder.repo_id),
        sessionToken
      );
      return {
        projectId,
        members: response
      };
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export type RepoFolderScanOptions = {
  repoId: string;
  folder: string;
}

type loadRepoFolderResult = {
  repoId: string;
  folder: string;
  entities: ProjectEntity[]
}

export const loadRepoFolder = createAsyncThunk<
loadRepoFolderResult, RepoFolderScanOptions
>(
  'projects/load_repo_folder',
  async ({ repoId, folder }, { rejectWithValue, getState, dispatch }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      let repoToLoad = repoId;
      let folderToLoad = folder;
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoToLoad = folderEntity.repo_id;
        folderToLoad = '/';
      }
      const response = await loadRepoAPI(repoToLoad, folderToLoad, sessionToken);
      return {
        entities: response,
        folder,
        repoId
      };
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const loadPersonalLibrary = createAsyncThunk(
  'projects/load_personal_library',
  async (_, { rejectWithValue, getState }) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      let myLib = getPersonalLibrary(state);
      if(!myLib)
        myLib = await getPersonalLibraryAPI(sessionToken);
      return myLib;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export type FileIdentity = {
  repoId: RepoId;
  folder: string;
  file: ProjectEntity;
}

export const openFile = createAsyncThunk(
  'projects/open_file',
  async (
    { repoId, folder, file }: FileIdentity, { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoId = folderEntity.repo_id;
        folder = ""
      }
      const response = await getOpenLinkAPI(
        repoId, folder, file.name, sessionToken
      );
      dispatch(viewFile(response));
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

async function createAlbum(name: string) {
  await Media.createAlbum({name: name});
  const { albums } = await Media.getAlbums();
  return albums?.find(x => x.name === name);
}

async function getAlbumIdentifier(name: string): Promise<string|undefined> {
  const { albums } = await Media.getAlbums();
  let albumIdentifier: string|undefined = albums?.find(x => x.name === name)?.identifier;
  if (!albumIdentifier) {
    const album = await createAlbum(name);
    albumIdentifier = album?.identifier;
  }
  return albumIdentifier;
}

async function storeAsMedia(filename: string, path: string, directory: Directory){
  const storedFile = await Filesystem.stat({path, directory});
  const options = {
    path: storedFile?.uri,
    albumIdentifier: isIOS() ? undefined : await getAlbumIdentifier('Meraki')
  };
  if (isVideo(filename)) {
    await Media.saveVideo(options);
  } else {
    await Media.savePhoto(options);
  }
  await Filesystem.deleteFile({
    path: storedFile?.uri
  });
}

export const downloadFile = createAsyncThunk(
  'projects/download_file',
  async (
    { repoId, folder, file }: FileIdentity, { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      const originalRepoId = repoId;
      const originalFolder = folder;
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoId = folderEntity.repo_id;
        folder = "";
      }

      const filename = file.name;

      if (isWeb()) {
        // Download da browser
        return await downloadFileAPI(
            repoId, folder, filename, sessionToken
        );
      } else if(window.electron?.downloadURL) {
        const downloadUrl: string = await generateFileDownloadLinkAPI(
            repoId, folder, filename, sessionToken
        );
        window.electron.downloadURL(downloadUrl);
      } else {
        // Download nativo

        const location = `${repoId}/${folder}/${filename}`;
        const previousDownload = getDownloadStatus(location)(state);
        if (previousDownload?.state === 'downloading') throw new Error('Stai già scaricando questo file');
        dispatch(setDownloadStatus({
          repoId,
          folder,
          filename,
          status: {
            downloadedBytes: 0,
            totalBytes: 1,
            state: 'downloading'
          }
        }));

        await checkWritePermissions();

        const directory = Directory.Documents;
        const path = `Download/${filename}`;

        if (await fileExists(path)) {
          if(!window.confirm('File già scaricato! Vuoi sostituirlo?')) {
            return;
          }
        }

        const downloadUrl: string = await generateFileDownloadLinkAPI(
            repoId, folder, filename, sessionToken
        );

        const controller = AbortController ? new AbortController() : undefined;
        const request = await fetch(
            downloadUrl,
            {
              method: 'GET',
              cache: 'no-cache',
              redirect: 'follow',
              signal: controller?.signal
            }
        );

        const contentLength = request.headers.get('content-length') || '0';
        const total = parseInt(contentLength, 10);
        let loaded = 0;
        let canceled = false;

        try {
          const reader = request.body?.getReader();
          if(!reader) throw new Error("Errore nella richiesta di download");
          const stream = new ReadableStream({
            start(streamController) {
              async function pumpStream(): Promise<void>{
                if(reader){
                  const {done, value} = await reader.read();
                  if(!done){
                    loaded += value.byteLength;
                    let downloadedState = await getState() as RootState;
                    const status = getDownloadStatus(location)(downloadedState);
                    if(status?.state !== 'canceled') {
                      dispatch(setDownloadStatus({
                        repoId,
                        folder,
                        filename,
                        status: {
                          downloadedBytes: loaded,
                          totalBytes: total,
                          state: 'downloading',
                          controller
                        }
                      }));
                      streamController.enqueue(value);
                      return pumpStream();
                    }else
                      canceled = true;
                  }
                }
                streamController.close();
                return;
              }
              return pumpStream();
            },
          });
          const response = new Response(stream);
          if (!response.ok) throw new Error(`Impossibile scaricare il file ${file.name}`);
          let blob = await response.blob();
          if(canceled) throw new Error(`Download ${filename} annullato`);

          await writeBlobToFile(path, directory, blob);

          if (isMedia(filename)) await storeAsMedia(filename, path, directory);
          dispatch(removeDownloadStatusEntry(location));
        } catch(error) {
          if (canceled) {
            dispatch(removeDownloadStatusEntry(location));
          } else {
            dispatch(setDownloadStatus({
              repoId,
              folder,
              filename,
              status: {
                downloadedBytes: loaded,
                totalBytes: total,
                error: error as Error,
                state: 'canceled',
                data: {
                  repo: originalRepoId,
                  folder: originalFolder,
                  entity: file
                }
              }
            }));
          }

          try {
            // This deletes the temporary empty file we store initially
            const storedFile = await Filesystem.stat({path, directory});
            await Filesystem.deleteFile({
              path: storedFile?.uri
            });
          } catch (e) {}

          throw error;
        }
      }
    } catch(error){
      return rejectWithValue(error);
    }
  }
);

type GetFileArguments = FileIdentity & {
  parse: ParsingType;
}
type GetFileResponse = {
  downloaded: any;
  entity: ProjectEntity;
}

export const getFile = createAsyncThunk(
  'projects/get_file',
  async (
    { repoId, folder, file, parse }: GetFileArguments,
    { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoId = folderEntity.repo_id;
        folder = "";
      }
      const response = await downloadFileAPI(
        repoId, folder, file.name, sessionToken,
        { getInApp: true, parseResponse: parse }
      );
      const toReturn: GetFileResponse = {
        downloaded: response,
        entity: file
      }
      return toReturn;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

type GetFileDetailsArguments = Omit<FileIdentity, 'file'> & {
  fileId: string;
}

export const loadFileDownloadLink = createAsyncThunk(
  'projects/get_file_download_link',
  async (
    { repoId, folder, file }: FileIdentity,
    { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoId = folderEntity.repo_id;
        folder = "/";
      }
      const downloadLink = await generateFileDownloadLinkAPI(
        repoId, folder, file.name, sessionToken
      );
      return {file, downloadLink};
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const loadFilePreviewLink = createAsyncThunk(
  'projects/get_file_preview_link',
  async (
    { repoId, folder, file }: FileIdentity,
    { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const downloadLink = await generateFilePreviewAPI(
        repoId, folder.substring(1), file, sessionToken
      );
      return {file, downloadLink};
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const loadFileDetails = createAsyncThunk(
  'projects/get_file_details',
  async (
    { repoId, folder, fileId }: GetFileDetailsArguments,
    { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const response = await dispatch(
        loadRepoFolder({ repoId, folder })
      ).then(unwrapResult);
      const details = response.entities.find(
        entity => entity.id === fileId
      )
      if(!details)
        throw new Error("File non trovato");
      return details;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export type FileIdentityRequest = {
  repoId: string;
  folder: string;
  file: File;
  options?: UploadOptions
}

type FileUploadStatusUpdate = {
  repoId: RepoId;
  folder: string;
  filename: string;
  status: UploadStatus;
}

type FileDownloadStatusUpdate = {
  repoId: RepoId;
  folder: string;
  filename: string;
  status: DownloadStatus
}

export const uploadFile = createAsyncThunk(
  'projects/upload_file',
  async (
    { repoId, folder, file, options }: FileIdentityRequest,
    { rejectWithValue, getState, dispatch }
  ) => {
    const chunkConfig: ChunkUploadOptions = {...options};
    const filename = options?.toReplace?.name || file.name;
    let sentBytes = 0;
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      const originalRepoId = repoId;
      const originalFolder = folder;
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoId = folderEntity.repo_id;
        folder = "/";
      }
      const location = `${repoId}/${folder}/${filename}`;
      const previousUpload = getUploadStatus(location)(state);
      if(previousUpload?.state === 'uploading')
        throw new Error('Stai già caricando questo file');
      try{
        const maxSize = getMaxUploadSize(state);
        let response: UploadedFile[] = [];
        const queue = SequentialQueue.getInstance();
        dispatch(setUploadStatus({
          repoId,
          folder,
          filename,
          status: {
            uploadedBytes: 0,
            totalBytes: file.size,
            state: 'uploading'
          }
        }));
        await new Promise((resolve, reject) => {
          queue.add(async () => {
            try{
              for(; sentBytes < file.size; sentBytes += maxSize){
                let updatedState = await getState() as RootState;
                const status = getUploadStatus(location)(updatedState);
                if(status?.state === 'canceled')
                  throw new Error('Caricamento annullato');
                const controller = AbortController ? new AbortController() : undefined;
                dispatch(setUploadStatus({
                  repoId,
                  folder,
                  filename,
                  status: {
                    uploadedBytes: sentBytes,
                    totalBytes: file.size,
                    state: 'uploading',
                    controller
                  }
                }));
                const currentEnd = Math.min(sentBytes + maxSize, file.size);
                const chunk = file.slice(sentBytes, currentEnd);
                const chunkResponse = await uploadChunkAPI(
                  repoId, folder, chunk, filename, sentBytes, file.size,
                  sessionToken, {...chunkConfig, controller}
                );
                if('uploadLink' in chunkResponse)
                  chunkConfig.uploadLink = chunkResponse.uploadLink;
                if(chunkResponse instanceof Array)
                  response = chunkResponse;
              }
              if(response.length > 0){
                let thumbnails: Blob[] = [];
                let sizes: number[] = [];
                if(isVideo(filename)){
                  const thumbnail = await generateVideoThumbnail(file);
                  if(thumbnail && file.size > thumbnail.size){
                    thumbnails = [thumbnail];
                    sizes = [256];
                  }
                }else if(isImage(filename)){
                  const thumbnail256 = await generateImageThumbnail(file, 256);
                  if(thumbnail256 && file.size > thumbnail256.size){
                    thumbnails.push(thumbnail256);
                    sizes.push(256);
                  }
                  const thumbnail1280 = await generateImageThumbnail(file, 1280);
                  if(thumbnail1280 && file.size > thumbnail1280.size){
                    thumbnails.push(thumbnail1280);
                    sizes.push(1280);
                  }
                }
                for(let i = 0; i < thumbnails.length; i++){
                  const thumbnail = thumbnails[i];
                  const size = sizes[i];
                  const uploadedFileName = response[0].name;
                  await setCustomThumbnailToFileAPI(
                    repoId, folder, uploadedFileName, thumbnail, size,
                    sessionToken, chunkConfig
                  );
                }
              }
              resolve(0);
            }catch(error){
              reject(error);
            }
          });
        });
        dispatch(removeUploadStatusEntry(location));
        return response;
      }catch(error){
        let updatedState = await getState() as RootState;
        const status = getUploadStatus(location)(updatedState);
        dispatch(setUploadStatus({
          repoId,
          folder,
          filename,
          status: {
            uploadedBytes: sentBytes,
            totalBytes: file.size,
            state: 'canceled',
            error: status?.error || (error as Error),
            data: {
              repo: originalRepoId,
              folder: originalFolder,
              file,
              options
            }
          }
        }));
        throw error;
      }
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

type FileThumbnailRequest = FileIdentity & {
  thumbnailOptions?: ThumbnailOptions
}

type ThumbnailRetrievalResponse = {
  file: ProjectEntity;
  thumbnail: string | null;
  transient: boolean
}

export const getFileThumbnail = createAsyncThunk(
  'projects/get_file_thumbnail',
  async (
    { repoId, folder, file, thumbnailOptions }: FileThumbnailRequest,
    { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoId = folderEntity.repo_id;
        folder = "";
      }
      const thumbnail = await getFileThumbnailAPI(
        repoId, folder, file, sessionToken, thumbnailOptions
      );
      let thumbnailUrl = thumbnail.url
      if (thumbnailUrl && !thumbnail.cachable) {
        const blob = (await getFileAPI(thumbnailUrl, sessionToken)) as Blob
        thumbnailUrl = URL.createObjectURL(blob)
      }
      const response: ThumbnailRetrievalResponse = {
        file,
        thumbnail: thumbnailUrl,
        transient: !thumbnail.cachable
      };
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const deleteFile = createAsyncThunk(
  'projects/delete_file',
  async (
    { repoId, folder, file }: FileIdentity, { rejectWithValue, getState, dispatch }
  ) => {
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoId = folderEntity.repo_id;
        folder = "";
      }
      await deleteFileAPI(repoId, folder, file.name, sessionToken);
    }catch(error){
      return rejectWithValue(error);
    }
  }
);

export const renameFile = createAsyncThunk(
  'projects/rename_file',
  async (
    { entity, newName }: { entity: FileIdentity, newName: string },
    { rejectWithValue, getState, dispatch }
  ) => {
    let { repoId, folder, file } = entity;
    try{
      const state = await getState() as RootState;
      const sessionToken = getSessionToken(state);
      if(!sessionToken) throw new Error("Non hai effettuato l'accesso.");
      const projects = await dispatch(loadMine()).then(unwrapResult);
      const project = projects.find(project => project.id === repoId);
      if(project){
        const folderEntity = project.folders.find(element => element.folder === folder);
        if(!folderEntity) throw new Error("Cartella non trovata.");
        repoId = folderEntity.repo_id;
        folder = "";
      }
      await renameFileAPI(repoId, folder, file.name, newName, sessionToken);
      if(isVideo(newName) || isImage(newName)){
        const filenameSlices = file.name.split('.');
        const filenameSlicesNew = newName.split('.');
        const oldThumbnailName1 = `${filenameSlices.slice(0, -1).join('.')}.tmb`;
        const newThumbName1 = `${filenameSlicesNew.slice(0, -1).join('.')}.tmb`;
        const oldThumbnailName2 = `${filenameSlices.slice(0, -1).join('.')}_${filenameSlices[filenameSlices.length - 1]}.tmb`;
        const newThumbName2 = `${filenameSlicesNew.slice(0, -1).join('.')}_${filenameSlicesNew[filenameSlicesNew.length - 1]}.tmb`;
        const oldThumbnailName3 = `${filenameSlices.slice(0, -1).join('.')}_${filenameSlices[filenameSlices.length - 1]}.256.tmb`;
        const newThumbName3 = `${filenameSlicesNew.slice(0, -1).join('.')}_${filenameSlicesNew[filenameSlicesNew.length - 1]}.256.tmb`;
        const oldThumbnailName4 = `${filenameSlices.slice(0, -1).join('.')}_${filenameSlices[filenameSlices.length - 1]}.1280.tmb`;
        const newThumbName4 = `${filenameSlicesNew.slice(0, -1).join('.')}_${filenameSlicesNew[filenameSlicesNew.length - 1]}.1280.tmb`;
        for(
          const [oldName, newName] of
          [
            [oldThumbnailName1, newThumbName1],
            [oldThumbnailName2, newThumbName2],
            [oldThumbnailName3, newThumbName3],
            [oldThumbnailName4, newThumbName4]
          ]
        ){
          try{
            await renameFileAPI(
              repoId, folder, oldName, newName, sessionToken
            );
          }catch{}
        }
      }
      return { entity, newName }
    }catch(error){
      return rejectWithValue(error);
    }
  }
);


export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setEntityToRename: (state, action: PayloadAction<FileIdentity>) => {
      state.entityToRename = action.payload;
    },
    removeEntityToRename: (state) => {
      state.entityToRename = null;
    },
    setUploadStatus: (state, action: PayloadAction<FileUploadStatusUpdate>) => {
      const update = action.payload;
      const location = `${update.repoId}/${update.folder}/${update.filename}`;
      state.currentUploads[location] = update.status;
    },
    cancelUpload: (state, action: PayloadAction<string>) => {
      const location = action.payload;
      let controller = null;
      const status = state.currentUploads[location];
      if(isUploadingStatus(status))
        controller = status.controller;
      state.currentUploads[location] = {
        ...status,
        state: 'canceled',
        error: new Error("Annullato")
      };
      controller?.abort();
    },
    removeUploadStatusEntry: (state, action: PayloadAction<string>) => {
      const location = action.payload;
      delete state.currentUploads[location];
    },
    setDownloadStatus: (state, action: PayloadAction<FileDownloadStatusUpdate>) => {
      const download = action.payload;
      const location = `${download.repoId}/${download.folder}/${download.filename}`;
      state.currentDownloads[location] = download.status;
    },
    cancelDownload: (state, action: PayloadAction<string>) => {
      const location = action.payload;
      let controller = null;
      const status = state.currentDownloads[location];
      if(isDownloadingStatus(status))
        controller = status.controller;
      state.currentDownloads[location] = {
        ...state.currentDownloads[location],
        state: 'canceled'
      };
      controller?.abort();
    },
    removeDownloadStatusEntry: (state, action: PayloadAction<string>) => {
      const location = action.payload;
      delete state.currentDownloads[location];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadFileDetails.fulfilled, (state, action: PayloadAction<ProjectEntity>) => {
        state.status = 'idle';
        const entity = action.payload;
        state.loadedDetails[entity.id] = entity;
      })
      .addCase(
        loadProjectMembers.fulfilled,
        (state, action: PayloadAction<loadProjectMembersResult>) => {
          state.status = 'idle';
          state.loadedProjectMembers[action.payload.projectId] =
            action.payload.members;
        }
      )
      .addCase(
        loadRepoFolder.fulfilled,
        (state, action: PayloadAction<loadRepoFolderResult>) => {
          state.status = 'idle';
          const payload = action.payload;
          const virtualPath = `${payload.repoId}/${payload.folder}`;
          state.loadedFolder[virtualPath] = payload.entities;
        }
      )
      .addCase(
        getFileThumbnail.fulfilled,
        (state, action: PayloadAction<ThumbnailRetrievalResponse>) => {
          state.status = 'idle';
          const { file, thumbnail, transient } = action.payload;
          if(thumbnail)
            state.loadedThumbnails = {
              ...state.loadedThumbnails,
              [file.id]: {
                thumbnail,
                timestamp: Date.now(),
                transient
              }
            };
        }
      )
      .addCase(
        getFile.fulfilled,
        (state, action: PayloadAction<GetFileResponse>) => {
          state.status = 'idle';
          const { entity, downloaded } = action.payload;
          state.downloadedFiles[entity.id] = downloaded;
        }
      )
      .addCase(
        loadPersonalLibrary.fulfilled,
        (state, action: PayloadAction<RepoId>) => {
          state.status = 'idle';
          state.personalLib = action.payload;
        }
      )
      .addCase(
        loadFileDownloadLink.fulfilled,
        (state, action: PayloadAction<{file: ProjectEntity, downloadLink: string}>) => {
          state.status = 'idle';
          const { downloadLink, file } = action.payload
          state.downloadLinks = {
            ...state.downloadLinks,
            [file.id]: {
              url: downloadLink,
              timestamp: Date.now()
            }
          }
        }
      )
      .addCase(
        loadFilePreviewLink.fulfilled,
        (state, action: PayloadAction<{file: ProjectEntity, downloadLink: string}>) => {
          state.status = 'idle';
          const { downloadLink, file } = action.payload
          state.previewLinks = {
            ...state.previewLinks,
            [file.id]: {
              url: downloadLink,
              timestamp: Date.now()
            }
          }
        }
      )
      .addCase(
        renameFile.fulfilled,
        (state, action: PayloadAction<{ entity: FileIdentity, newName: string }>) => {
          state.status = 'idle';
          const entity = action.payload.entity;
          const changed = entity.file;
          const virtualPath = `${entity.repoId}/${entity.folder}`;
          let nameIndex = 0;
          let newName = action.payload.newName.toLowerCase();
          while(
            state.loadedFolder[virtualPath]
              // eslint-disable-next-line no-loop-func
              .find(file => file.name === newName)
          ){
            nameIndex++;
            const lowered = action.payload.newName.toLowerCase();
            const onlyName = lowered.split('.').slice(0, -1).join('.');
            const extension = lowered.split('.').slice(-1).join('');
            newName = `${onlyName} (${nameIndex}).${extension}`;
          }
          state.loadedFolder[virtualPath] = state.loadedFolder[virtualPath].map(
            file => {
              if(file.name === changed.name){
                return {
                  ...file,
                  name: newName
                };
              }else
                return file;
            }
          )
        }
      )
      .addMatcher(
        isAnyOf(loadMine.fulfilled, loadMineForcely.fulfilled),
          (state, action: PayloadAction<Project[]>) => {
          state.status = 'idle';
          state.mine = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(
          loadMine.pending, loadProjectMembers.pending, loadRepoFolder.pending,
          openFile.pending, downloadFile.pending, uploadFile.pending,
          getFileThumbnail.pending, getFile.pending, deleteFile.pending,
          loadFileDetails.pending, loadPersonalLibrary.pending, loadFileDownloadLink.pending,
          renameFile.pending, loadMineForcely.pending, loadFilePreviewLink.pending
        ),
        (state) => {
          state.status = 'loading';
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          loadMine.rejected, loadProjectMembers.rejected, loadRepoFolder.rejected,
          openFile.rejected, downloadFile.rejected, uploadFile.rejected,
          getFileThumbnail.rejected, getFile.rejected, deleteFile.rejected,
          loadFileDetails.rejected, loadPersonalLibrary.rejected, loadFilePreviewLink.rejected,
          loadFileDownloadLink.rejected, renameFile.rejected, loadMineForcely.rejected
        ),
        (state, action) => {
          state.status = 'failed';
          const error = action.payload;
          state.error = (error instanceof Error) ? error : new Error(error as string);
        }
      )
      .addMatcher(
        isAnyOf(login.fulfilled),
        (state) => {
          state.downloadedFiles = {};
          state.loadedThumbnails = {};
          state.loadedFolder = {};
          state.loadedDetails = {};
          state.loadedProjectMembers = {};
          state.personalLib = null;
          state.mine = null;
          state.currentUploads = {};
          state.downloadLinks = {};
          state.previewLinks = {};
        }
      );
  },
});

export const {
  cancelUpload, setUploadStatus, cancelDownload, setDownloadStatus, removeUploadStatusEntry, removeDownloadStatusEntry, setEntityToRename,
  removeEntityToRename
} = projectsSlice.actions;

export const isLoading = (state: RootState) => state.projects.status === 'loading';
export const getError = (state: RootState) => state.projects.error;
export const getMine = (state: RootState) => state.projects.mine;
export const getLoadedProjectMembers = (projectId: string) =>
  (state: RootState): ProjectMember[] | undefined =>
    state.projects.loadedProjectMembers[projectId];
export const getProject =
  (id: string) =>
    (state: RootState) => state.projects.mine?.find(project => project.id === id);
export const getLoadedFolder = (projectId: string, folder: string) =>
  (state: RootState) => state.projects.loadedFolder[`${projectId}/${folder}`];
export const getLoadedFileThumbnail =
  (entity: ProjectEntity) =>
    (state: RootState): string | undefined => state.projects.loadedThumbnails[entity.id]?.thumbnail;
export const getDownloaded =
  (entity: ProjectEntity) =>
    (state: RootState): any => state.projects.downloadedFiles[entity.id];
export const getFileDetails =
  (entityId: string) =>
    (state: RootState) => state.projects.loadedDetails[entityId];
export const getPersonalLibrary =
  (state: RootState) => state.projects.personalLib;
export const getCurrentUploadsList =
    (state: RootState) => Object.entries<UploadStatus>(state.projects.currentUploads);
export const getCurrentDownloadsList =
    (state: RootState) => Object.entries<DownloadStatus>(state.projects.currentDownloads);
export const getUploadStatus = (location: string) =>
  (state: RootState): UploadStatus | undefined => state.projects.currentUploads[location];
export const getDownloadStatus = (location: string) =>
  (state: RootState): DownloadStatus | undefined => state.projects.currentDownloads[location];
export const getFirstNPendingUploads = (n: number) =>
  (state: RootState): string[] => Object.entries(state.projects.currentUploads).filter(
    element => element[1].state === "uploading"
  ).slice(0, n).map(element => element[0]);
export const getEntityToRename = (state: RootState) => state.projects.entityToRename;
export const getEntityDownloadLink =
  (file: ProjectEntity) =>
    (state: RootState) => state.projects.downloadLinks[file.id]?.url
export const getEntityPreviewLink =
  (file: ProjectEntity) =>
    (state: RootState) => state.projects.previewLinks[file.id]?.url

type MediaRecord = {
  timestamp: number
  transient?: boolean
}
type UrlMediaRecord = MediaRecord & {
  url: string
}
type BlobMediaRecord = MediaRecord & {
  thumbnail: string | null
}
type MediaMap = Record<string, UrlMediaRecord | BlobMediaRecord>
const MediaCacheTransform = createTransform<MediaMap, MediaMap>(
  (inboundState: MediaMap) => {
    const cacheMap: MediaMap = {};
    const limit = Date.now() - 604800000
    for (const key in inboundState) {
      const value = inboundState[key];
      if (!value.transient && value.timestamp > limit) {
        cacheMap[key] = value;
      }
    }
    return cacheMap;
  },
  (outboundState: MediaMap) => outboundState,
  { whitelist: ['downloadLinks', 'loadedThumbnails'] }
);

const persistConfig = {
  key: 'projects',
  storage: CapacitorStorage,
  transforms: [MediaCacheTransform],
  whitelist: ['downloadLinks', 'loadedThumbnails'],
};
export default (Capacitor.isNativePlatform()
  ? persistReducer<ProjectsState>(persistConfig, projectsSlice.reducer)
  : projectsSlice.reducer) as Reducer<ProjectsState>;
